import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { deepClone } from '@/utils/methods/common';

// 解析参数
const xlsxParam = {
  raw: true
};

// 默认参数
const defaultOptions = {
  fileType: 'xlsx', // 文件格式
  fileName: '新建文件', // 文件名
  showSummary: false, // 是否带合计信息
  summaryMethod: () => {}, // 合计信息计算函数
  sheetName: 'Sheet1', // 工作表名
  vueComponent: {} // vue 实例，用于 formatter、summaryMethod 等需要调用页面变量的场合
};

/**
 * tableColumns 用到的字段
 * label：列名
 * prop：列数据对应字段
 * width：列宽，默认为 120px
 * formatter：自定义文本
 * merge：合并列配置，结构与 tableColumns 一致
 * excel：单元格格式，一般包含 t、z、l 等属性，也可以传 formatter（仅用于 Excel 导出，优先级高于 tableColumns 的 formatter）
 *    t、z 等属性参考下面注释
 *    l 的 Target 属性和 Tooltip 属性，可传 string（即 prop） 或者 function（需要 return value），function 参数与 formatter 一致
 */
/**
 * 单元格对象 v: 原始值
 * 单元格对象 t: 格式（b: Boolean, e: Error, n: Number, d: Date, s: Text, z: Stub）
 * 单元格对象 l: 超链接对象（.Target 长联接, .Tooltip 是提示消息）
 * 单元格对象 z: t 为 n 时的具体数值格式
 * 0	General
 * 1	0
 * 2	0.00
 * 3	#,##0
 * 4	#,##0.00
 * 9	0%
 * 10	0.00%
 * 11	0.00E+00
 * 12	# ?/?
 * 13	# ??/??
 * 14	m/d/yy (see below)
 * 15	d-mmm-yy
 * 16	d-mmm
 * 17	mmm-yy
 * 18	h:mm AM/PM
 * 19	h:mm:ss AM/PM
 * 20	h:mm
 * 21	h:mm:ss
 * 22	m/d/yy h:mm
 * 37	#,##0 ;(#,##0)
 * 38	#,##0 ;[Red](#,##0)
 * 39	#,##0.00;(#,##0.00)
 * 40	#,##0.00;[Red](#,##0.00)
 * 45	mm:ss
 * 46	[h]:mm:ss
 * 47	mmss.0
 * 48	##0.0E+0
 * 49	@
 */

/**
 * 导出 table 元素
 * @param {object} el table 元素，或子元素包含 table 的元素/$ref
 * @param {object} options 参数配置
 */
export function exportTableExcel(el, options) {
  const workbook = XLSX.utils.table_to_book(el, xlsxParam);
  downloadFile(workbook, options);
}
/**
 * 导出 el-table 元素（包括组件库的 table）
 * @param {object} el el-table 元素，可传 $refs.$el
 * @param {object} options 参数配置
 */
export function exportElTableExcel(el, options) {
  const nodes = el.querySelectorAll('table');
  const tableNode = document.createElement('div');
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].parentNode.className.indexOf('fixed') === -1) {
      tableNode.appendChild(nodes[i].cloneNode(true));
    }
  }
  const workbook = XLSX.utils.table_to_book(tableNode, xlsxParam);
  downloadFile(workbook, options);
}
/**
 * 导出组件库 table 缓存列数据（无缓存导出默认配置）
 * @param {string} tableId 表格 id
 * @param {array} tableColumns table 配置项
 * @param {array} tableData table 数据
 * @param {object} options 参数配置
 */
export function exportCachedTableDataExcel(
  tableId,
  tableColumns,
  tableData,
  options
) {
  const cache = localStorage.getItem('tableColumnsCache')
    ? JSON.parse(localStorage.getItem('tableColumnsCache'))
    : {};
  if (cache[tableId]) {
    const columns = cache[tableId].filter((item) => {
      return item.filter !== true;
    });
    exportTableDataExcel(columns, tableData, options);
  } else {
    exportTableDataExcel(tableColumns, tableData, options);
  }
}
/**
 * 导出组件库 table 数据
 * @param {array} tableColumns table 配置项
 * @param {array} tableData table 数据
 * @param {object} options 参数配置
 */
export function exportTableDataExcel(tableColumns, tableData, options) {
  options = { ...defaultOptions, ...options };
  let workbook = XLSX.utils.book_new();
  let { sheetData, colData, mergeData, dataFormat } = getSheetData(
    tableColumns,
    tableData,
    options
  );
  let worksheet = XLSX.utils.aoa_to_sheet(sheetData, xlsxParam);
  // 配置列宽
  if (colData) {
    worksheet['!cols'] = colData.map((item) => {
      return { wpx: item };
    });
  }
  // 配置合并列
  if (mergeData) {
    worksheet['!merges'] = mergeData;
  }
  // 设置单元格格式
  Object.keys(worksheet).forEach((item) => {
    if (
      Object.prototype.toString.call(dataFormat[item]) === '[object Object]'
    ) {
      worksheet[item].t = dataFormat[item].t
        ? dataFormat[item].t
        : worksheet[item].t;
      worksheet[item].z = dataFormat[item].z
        ? dataFormat[item].z
        : worksheet[item].z;
      worksheet[item].l =
        dataFormat[item].l &&
        dataFormat[item].l.Target &&
        dataFormat[item].l.Tooltip
          ? dataFormat[item].l
          : worksheet[item].l;
    }
  });
  XLSX.utils.book_append_sheet(
    workbook,
    worksheet,
    options.sheetName || 'Sheet1'
  );
  // 隐藏编辑
  // Object.keys(workbook.Sheets).forEach((item, index) => {
  //   XLSX.utils.book_set_sheet_visibility(workbook, index, 2);
  // });
  downloadFile(workbook, options);
}

/**
 * 整合 sheet 数据
 * @param {array} tableColumns table 配置项
 * @param {array} tableData table 数据
 * @param {object} options 参数配置
 * @returns { array, array, array, object } { sheetData, colData, mergeData, dataFormat } { 工作表数据, 列宽数据, 合并项数据, 单元格格式 }
 */
function getSheetData(tableColumns, tableData, options) {
  // 默认去除 操作 列
  tableColumns = tableColumns.filter((item) => {
    return item.prop !== 'operate';
  });
  // 存在合计行时，默认留空一列
  if (options.showSummary) {
    tableColumns = [{}, ...tableColumns];
  }
  let { headerData, mergeData, colData, originHeaderData } = dealWithHeaderData(
    tableColumns
  );
  let { mainData, mainDataFormat } = dealWithMainData(
    tableData,
    originHeaderData,
    headerData,
    options
  );
  let { footerData, footerDataFormat } = dealWithFooterData(
    tableData,
    originHeaderData,
    headerData,
    options
  );
  const sheetData = [...headerData, ...mainData, footerData];
  const dataFormat = { ...mainDataFormat, ...footerDataFormat };
  return { sheetData, mergeData, colData, dataFormat };
}
/**
 * 处理表头数据
 * @param {array} tableColumns table 配置项
 * @returns { array, array, array, array } { headerData, mergeData, colData, originHeaderData } { 表头数据, 表头合并项数据, 表头列宽数据, 表头原始数据 }
 */
function dealWithHeaderData(tableColumns) {
  const hasMerge = tableColumns.some((item) => {
    return Array.isArray(item.merge) && item.merge.length > 0;
  });
  let mergeHeader = {
    originHeaderData: [],
    headerData: [],
    mergeData: [],
    colData: []
  };
  if (hasMerge) {
    mergeHeader = getMergeHeader(tableColumns);
  } else {
    mergeHeader.headerData[0] = [];
    tableColumns.forEach((item) => {
      mergeHeader.originHeaderData.push(item);
      mergeHeader.headerData[0].push(item.label);
      mergeHeader.colData.push(item.width || 120);
    });
  }
  return mergeHeader;
}
/**
 * 获取合并项表头
 * @param {array} tableColumns table 配置项
 * @returns { array, array, array, array } { headerData, mergeData, colData, originHeaderData } { 表头数据, 表头合并项数据, 表头列宽数据, 表头原始数据 }
 */
function getMergeHeader(tableColumns) {
  let maxColLength = 1; // 最大占高数
  /**
   * 在 table 配置项中插入 row、col 等占位信息
   * @param {object} columns 当前 table 配置项，或合并项 merge 配置项
   */
  const updateColumnsData = (columns) => {
    columns.forEach((item) => {
      item.rowLength = 1;
      item.colLength = 1;
      if (Array.isArray(item.merge) && item.merge.length > 0) {
        const colLength = getColumnsLength(item.merge);
        item.rowLength = colLength.rowLength;
        item.colLength = colLength.colLength;
        maxColLength = Math.max(maxColLength, item.colLength);
        updateColumnsData(item.merge);
      }
    });
  };
  updateColumnsData(tableColumns);
  let mergeData = [];
  let columnLevel = [];
  /**
   * 整合 table 配置项数据为 Excel 所需分层数据，并添加合并项配置
   * @param {array} tableColumns table 配置项
   */
  const packageData = (tableColumns) => {
    /**
     * 添加没有 merge 的表头配置项到 Excel 数据
     * @param {object} column 当前无 merge 的配置项
     * @param {number} col Excel 数据层级
     * @param {number} startCol Excel 数据开始层级
     */
    const addColumn = (column, col = 0, startCol = 0) => {
      if (!columnLevel[col]) {
        columnLevel[col] = [];
      }
      columnLevel[col].push(column);
      if (col < maxColLength - 1) {
        addColumn(column, col + 1, startCol);
      } else {
        // 没有 merge 的配置项需要纵向合并单元格
        mergeData.push({
          s: { c: columnLevel[col].length - 1, r: startCol },
          e: { c: columnLevel[col].length - 1, r: col }
        });
      }
    };
    /**
     * 添加有 merge 的表头配置项到 Excel 数据
     * @param {object} column 当前有 merge 的配置项
     * @param {number} col Excel 数据层级
     */
    const addMergeColumn = (column, col = 0) => {
      if (!columnLevel[col]) {
        columnLevel[col] = [];
      }
      columnLevel[col].push(column);
      Array.from({ length: column.rowLength - 1 }, () => {
        columnLevel[col].push({});
      });
      if (column.rowLength - 1 > 0) {
        // 有 merge 的配置项需要横向合并单元格
        mergeData.push({
          s: { c: columnLevel[col].length - column.rowLength, r: col },
          e: { c: columnLevel[col].length - 1, r: col }
        });
      }
      if (col < maxColLength - 1) {
        if (Array.isArray(column.merge) && column.merge.length > 0) {
          column.merge.forEach((item) => {
            addMergeColumn(item, col + 1);
          });
        } else {
          addColumn(column, col + 1, col);
        }
      }
    };
    tableColumns.forEach((item) => {
      if (Array.isArray(item.merge) && item.merge.length > 0) {
        addMergeColumn(item);
      } else {
        addColumn(item);
      }
    });
  };
  packageData(tableColumns);
  let colData = [];
  let originHeaderData = [];
  let headerData = columnLevel.map((item, index) => {
    if (columnLevel.length === index + 1) {
      originHeaderData = item;
    }
    return item.map((subItem) => {
      colData.push(subItem.width || 120);
      return subItem.label;
    });
  });
  return { headerData, colData, mergeData, originHeaderData };
}
/**
 * 获取表头项 row 横向、col 纵向各占有的长度
 * @param {array} mergeColumns 表头项的合并项 merge 配置
 * @param {number} lineIndex 当前 col 长度
 * @returns { number, number } { rowLength, colLength } { row 横向占有的长度, col 纵向占有的长度 }
 */
function getColumnsLength(mergeColumns, lineIndex = 2) {
  let rowLength = mergeColumns.length;
  let columnsLength = {};
  mergeColumns.forEach((item) => {
    if (Array.isArray(item.merge) && item.merge.length > 0) {
      columnsLength = getColumnsLength(item.merge, lineIndex + 1);
      rowLength += columnsLength.rowLength - 1;
    }
  });
  let colLength = columnsLength.colLength || lineIndex;
  return { rowLength, colLength };
}
/**
 *  处理表格主体数据
 * @param {array} tableData table 数据
 * @param {array} originHeaderData 原始表头数据
 * @param {array} headerData Excel 表头数据
 * @param {object} options 参数配置
 * @returns {array, object} { mainData, mainDataFormat } {表格主体数据，表格主体格式}
 */
function dealWithMainData(tableData, originHeaderData, headerData, options) {
  let mainDataFormat = {};
  const mainData = tableData.map((item, index) => {
    return originHeaderData.map((columnItem, columnIndex) => {
      const formatter =
        (columnItem.excel && columnItem.excel.formatter) ||
        columnItem.formatter;
      const value =
        (formatter
          ? formatter.call(
              options.vueComponent,
              item,
              { ...columnItem, property: columnItem.prop },
              item[columnItem.prop],
              index
            )
          : item[columnItem.prop]) || '';
      // 提取单元格格式，无数据时不强制设置格式
      const cellAddress = {
        c: columnIndex,
        r: headerData.length + index
      };
      const cellRef = XLSX.utils.encode_cell(cellAddress);
      mainDataFormat[cellRef] = value ? deepClone(columnItem.excel) : undefined;
      // 设置超链接
      if (
        mainDataFormat[cellRef] &&
        Object.prototype.toString.call(mainDataFormat[cellRef].l) ===
          '[object Object]'
      ) {
        mainDataFormat[cellRef].l.Target =
          (typeof mainDataFormat[cellRef].l.Target === 'string' &&
            item[mainDataFormat[cellRef].l.Target]) ||
          (typeof mainDataFormat[cellRef].l.Target === 'function' &&
            mainDataFormat[cellRef].l.Target.call(
              options.vueComponent,
              item,
              { ...columnItem, property: columnItem.prop },
              item[columnItem.prop],
              index
            )) ||
          '';
        mainDataFormat[cellRef].l.Tooltip =
          (typeof mainDataFormat[cellRef].l.Tooltip === 'string' &&
            item[mainDataFormat[cellRef].l.Tooltip]) ||
          (typeof mainDataFormat[cellRef].l.Tooltip === 'function' &&
            mainDataFormat[cellRef].l.Tooltip.call(
              options.vueComponent,
              item,
              { ...columnItem, property: columnItem.prop },
              item[columnItem.prop],
              index
            )) ||
          '';
      }
      return value;
    });
  });
  return { mainData, mainDataFormat };
}
/**
 * 处理合计项数据
 * @param {array} tableData table 数据
 * @param {array} originHeaderData 原始表头数据
 * @param {array} headerData Excel 表头数据
 * @param {object} options 参数配置
 * @returns {array, object} { footerData, footerDataFormat } {表格合计项数据，表格合计项格式}
 */
function dealWithFooterData(tableData, originHeaderData, headerData, options) {
  let footerData = [];
  let footerDataFormat = {};
  if (options.showSummary) {
    // 提取单元格格式，只在格式为 number 时设置合计项格式
    originHeaderData.forEach((item, index) => {
      if (
        Object.prototype.toString.call(item.excel) === '[object Object]' &&
        item.excel.t === 'n'
      ) {
        const cellAddress = {
          c: index,
          r: headerData.length + tableData.length
        };
        footerDataFormat[XLSX.utils.encode_cell(cellAddress)] = item.excel;
      }
    });
    if (typeof options.summaryMethod === 'function') {
      footerData = options.summaryMethod.call(options.vueComponent, {
        columns: originHeaderData.map((item) => ({
          ...item,
          property: item.prop
        })),
        data: tableData
      });
    } else {
      footerData = originHeaderData.map((item, index) => {
        if (index === 0) {
          return '合计';
        } else {
          const num = tableData.reduce((total, subItem) => {
            if (
              subItem[item.prop] !== true &&
              subItem[item.prop] !== false &&
              !isNaN(Number(subItem[item.prop]))
            ) {
              return Number(total) + Number(subItem[item.prop]);
            }
          }, '');
          return num;
        }
      });
    }
  }
  return { footerData, footerDataFormat };
}
/**
 * 下载文件
 * @param {*} workbook 工作簿
 * @param {object} downloadOptions 下载参数配置
 */
function downloadFile(workbook, downloadOptions = {}) {
  const { fileType, fileName } = downloadOptions;
  const workbout = XLSX.write(workbook, {
    bookType: fileType,
    bookSST: false,
    type: 'array'
  });
  FileSaver.saveAs(
    new Blob([workbout], { type: 'application/octet-stream' }),
    `${fileName}.${fileType}`
  );
}
