import { handleComponent } from '../../src/utils';
import { deepClone } from '@/utils/methods/common';
import resize from '@/mixins/resize';

export default {
  mixins: [resize],
  props: {
    config: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showConfig_: [], // 平铺展示的配置
      moreConfig_: [], // 收起到更多里的配置
      dropdownWidth: 0 // 更多按钮的宽度
    };
  },
  computed: {
    config_() {
      return this.config.reduce((sum, item) => {
        item.component = handleComponent(item);
        item.callback = item.callback || (() => {});
        if (!item.code) {
          sum.push(item);
        } else {
          const resourceBtns = this.$store.getters.btns[
            this.$store.getters.activeMenu.name
          ];
          let temp =
            resourceBtns &&
            resourceBtns.find((btnItem) => {
              return btnItem.code === item.code;
            });
          if (temp) {
            sum.push(Object.assign(deepClone(temp), item));
          }
        }
        return sum;
      }, []);
    }
  },
  watch: {
    // 监听容器宽度变化
    mixin_resize_containerWidth() {
      this.computedConfigShow();
    },
    // 监听配置数量发生变化，或配置名发生变化
    config_(newVal, oldVal) {
      if (newVal.length !== oldVal.length) {
        this.computedConfigShow();
        return;
      }
      for (let i = 0; i < newVal.length; i++) {
        if (newVal[i].name !== oldVal[i].name) {
          this.computedConfigShow();
          return;
        }
      }
    }
  },
  mounted() {
    // 初始化获取更多按钮宽度，用于后面计算累加宽度
    this.moreConfig_ = [{}];
    this.$nextTick(() => {
      const dropdownStyle = getComputedStyle(this.$refs.dropdown.$el);
      this.dropdownWidth =
        parseFloat(dropdownStyle.width) +
        parseFloat(dropdownStyle.marginLeft) +
        parseFloat(dropdownStyle.marginRight);
      this.computedConfigShow();
    });
  },
  methods: {
    computedConfigShow() {
      // 默认平铺展开，用于计算每个元素的宽度
      this.showConfig_ = [...this.config_];
      this.moreConfig_ = [];
      this.$nextTick(() => {
        // 容器总宽度
        const containerStyle = getComputedStyle(this.mixin_resize_container);
        let mixin_resize_containerWidth =
          this.mixin_resize_containerWidth -
          (parseFloat(containerStyle.paddingLeft) || 0) -
          (parseFloat(containerStyle.paddingRight) || 0);
        if (mixin_resize_containerWidth <= 0) {
          return;
        }
        // 元素累加总宽度
        let elSumWidth = 0;
        // 上一个元素宽度
        let previousWidth = 0;
        // 遍历子元素，计算累加宽度
        for (let i = 0; i < this.$el.children.length; i++) {
          const contentStyle = getComputedStyle(this.$el.children[i]);
          const elTotalWidth =
            (parseFloat(contentStyle.width) || 0) +
            // parseFloat(contentStyle.paddingLeft) +
            // parseFloat(contentStyle.paddingRight) +
            parseFloat(contentStyle.borderLeftWidth) +
            parseFloat(contentStyle.borderRightWidth) +
            parseFloat(contentStyle.marginLeft) +
            parseFloat(contentStyle.marginRight);
          // 累计宽度（算上当前元素）小于等于容器宽度时，进行累加
          if (elSumWidth + elTotalWidth <= mixin_resize_containerWidth) {
            elSumWidth += elTotalWidth;
            previousWidth = elTotalWidth;
          } else {
            // 累计宽度（不算当前元素） + 更多按钮宽度 <= 容器宽度 时，将当前元素以及之后的元素全部归入更多按钮中
            if (
              elSumWidth + this.dropdownWidth <=
              mixin_resize_containerWidth
            ) {
              this.moreConfig_ = this.showConfig_.splice(
                i,
                this.showConfig_.length - i
              );
            } else if (
              elSumWidth - previousWidth + this.dropdownWidth <=
                mixin_resize_containerWidth ||
              i - 2 < 0
            ) {
              // 累计宽度（不算当前元素）- 上个元素宽度 + 更多按钮宽度 <= 容器宽度 时
              // 或元素数量 <= 2 时，将上一个元素以及之后的元素全部归入更多按钮中
              this.moreConfig_ = this.showConfig_.splice(
                i - 1,
                this.showConfig_.length - i + 1
              );
            } else {
              // 累计宽度（不算当前元素）- 上个元素宽度 + 更多按钮宽度 > 容器宽度 时
              // 且元素数量 > 2 时，将上上一个元素以及之后的元素全部归入更多按钮中
              this.moreConfig_ = this.showConfig_.splice(
                i - 2,
                this.showConfig_.length - i + 2
              );
            }
            // 没有平铺元素时，更多按钮前不缩进
            if (this.showConfig_.length === 0) {
              this.$refs.dropdown.$el.classList.remove('indent');
            } else {
              this.$refs.dropdown.$el.classList.add('indent');
            }
            break;
          }
        }
      });
    },
    // dropdown 组件的事件绑定
    handleCommand(configIndex) {
      if (this.moreConfig_[configIndex].loadingCallback) {
        this.moreConfig_[configIndex].loadingCallback(() => {},
        this.moreConfig_[configIndex]);
      } else {
        this.moreConfig_[configIndex].callback(this.moreConfig_[configIndex]);
      }
    }
  }
};
