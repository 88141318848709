<template>
  <el-table-column v-if="type === 'index'" v-bind="getColBind(col)">
    <template slot-scope="scope">{{
      pageSize * (pageNo - 1) + Number(scope.$index) + 1
    }}</template>
  </el-table-column>
  <el-table-column
    v-else-if="type === 'selection'"
    :type="type"
    v-bind="getColBind(col)"
  ></el-table-column>
  <el-table-column v-else-if="col.prop === 'operate'" v-bind="getColBind(col)">
    <template slot="header">
      <i
        class="el-icon-s-tools tool"
        @click="baseTable.openColumnsSetting"
        title="自定义列配置"
      ></i>
    </template>
    <template slot-scope="scope">
      <component
        :is="col.component"
        v-bind="getCptBind(scope, col)"
        v-on="col.listeners"
      ></component>
    </template>
  </el-table-column>
  <el-table-column v-else-if="col.component" v-bind="getColBind(col)">
    <loop-column
      v-for="childCol in col.merge"
      :key="childCol.prop"
      :col="childCol"
    ></loop-column>
    <template slot-scope="scope">
      <component
        :is="col.component"
        v-bind="getCptBind(scope, col)"
        v-on="col.listeners"
      ></component>
    </template>
  </el-table-column>
  <el-table-column v-else v-bind="getColBind(col)">
    <loop-column
      v-for="childCol in col.merge"
      :key="childCol.prop"
      :col="childCol"
    ></loop-column>
  </el-table-column>
</template>
<script>
  import { TableColumn } from 'element-ui';

  const COLUMN_PROPS = {
    align: 'left',
    showOverflowTooltip: true
  };
  export default {
    name: 'loop-column',
    inject: ['baseTable'],
    components: {
      elTableColumn: TableColumn
    },
    props: {
      type: String,
      col: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      pageSize() {
        return this.baseTable.query ? this.baseTable.query.pageSize : 0;
      },
      pageNo() {
        return this.baseTable.query ? this.baseTable.query.pageNo : 1;
      },
      data() {
        return this.baseTable.data || [];
      }
    },
    methods: {
      getColBind(col) {
        const bind = Object.assign({}, COLUMN_PROPS, col);
        delete bind.component;
        delete bind.listeners;
        delete bind.propsHandler;
        return bind;
      },
      getCptBind({ row, column, $index }, col) {
        let index = $index;
        let length = this.data.length;
        let cur = row[col.prop];
        let page = {
          pageNo: this.pageNo,
          pageSize: this.pageSize
        };
        const props = { row, col, column, index, length, page, cur };
        const handler = col.propsHandler;
        return (handler && handler(props)) || props;
      }
    }
  };
</script>
<style lang="less" scoped>
  .tool {
    cursor: pointer;
  }
</style>
