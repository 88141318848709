import Vue from 'vue';
import contextMenu from './main.vue';
export default function (event, config, data, style) {
  let Ext = Vue.extend(contextMenu);
  let vnode = new Ext({
    el: document.createElement('div'),
    data() {
      return {
        location: {
          x: event.pageX,
          y: event.pageY,
          overthrow: window.innerHeight - event.pageY < 120
        },
        config,
        data,
        style
      };
    }
  });
  document.body.appendChild(vnode.$el);
}
