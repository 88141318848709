<template>
  <div class="dog-input">
    <div class="dog-input-content" :style="{ width: width + 'px' }">
      <el-input
        ref="input"
        :value="query"
        v-bind="{
          placeholder: getPlaceholder('input', label),
          type: type !== 'number' ? type : undefined,
          size: 'small',
          resize: type === 'textarea' ? 'none' : null,
          ...$attrs
        }"
        @keydown.enter.native="confirm"
        @input="change"
        v-on="filterListeners"
      >
        <div v-if="suffixLabel" class="suffix-label" slot="suffix">
          {{ suffixLabel }}
        </div>
        <template v-for="key in Object.keys($slots)" :slot="key">
          <slot :name="key"></slot>
        </template>
      </el-input>
      <div class="dog-input-subscript" v-if="type === 'textarea' && subscript">
        <span>
          {{ value ? value.length : 0 }} /
          <template v-if="$attrs.maxlength">{{ $attrs.maxlength }}</template>
          <i v-else class="alicause-wuqiongda"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import { placeholder, getQuery } from '../../../src/mixins';
  // eslint-disable-next-line no-misleading-character-class
  const defaultReg = /([\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[9|E]\u3030|\uA9|\uAE|\u3030)+/;
  const floatReg = /^\d+(\.(\d{1,2})?)?$/;

  export default {
    mixins: [placeholder, getQuery],
    name: 'dog-input',
    props: {
      isAutoFocus: {
        type: Boolean,
        default: false
      },
      suffixLabel: {
        type: String
      },
      label: {
        type: String,
        default: '内容'
      },
      value: {
        default: undefined
      },
      type: {
        default: 'input'
      },
      subscript: {
        type: Boolean,
        default: false
      },
      width: {
        type: Number,
        default: null
      },
      height: {
        type: Number,
        default: null
      },
      allowSpace: {
        type: Boolean,
        default: false
      },
      allowSymbol: {
        type: Boolean,
        default: true
      },
      reg: {
        type: RegExp,
        default: null
      }
    },
    data() {
      return {
        query: this.getDefaultQuery()
      };
    },
    watch: {
      value() {
        this.query = this.value;
      }
    },
    mounted() {
      if (this.height !== null && this.type === 'textarea') {
        this.$el.querySelector('.dog-input-content textarea').style.height =
          this.height + 'px';
      }
      if (this.isAutoFocus) {
        this.focus();
      }
    },
    computed: {
      filterListeners() {
        return ['input'].reduce(
          (sum, key) => {
            delete sum[key];
            return sum;
          },
          { ...this.$listeners }
        );
      }
    },
    methods: {
      focus() {
        this.$refs.input.focus();
      },
      change(value) {
        let query = value;
        if (this.type === 'number') {
          query = query.replace(/\D/g, '');
        } else if (this.type === 'float') {
          if (query.replace(floatReg, '')) return;
        } else if (['input', 'textarea'].includes(this.type)) {
          if (!this.allowSymbol) {
            query = query.replace(defaultReg, '');
          }
          if (!this.allowSpace) {
            query = query.replace(' ', '');
          }
        }
        if (this.reg) {
          if (query.replace(this.reg, '')) return;
        }
        this.$emit('input', query);
      },
      confirm() {
        this.$emit('confirm');
      }
    }
  };
</script>
<style lang="less" scoped>
  .dog-input {
    &-subscript {
      height: 20px;
      font-size: 12px;
      line-height: 20px;
      text-align: right;
      span {
        display: inline-flex;
        i {
          font-size: 20px;
        }
      }
    }
  }
</style>
