<template>
  <div class="dog-autocomplete" :style="{ width: width + 'px' }">
    <el-autocomplete
      ref="autocomplete"
      v-model="value_"
      v-bind="{
        size: 'small',
        placeholder: '请输入内容',
        ...$attrs,
        valueKey: labelKey
      }"
      :fetch-suggestions="fetchSuggestions"
      v-on="filterListeners"
      @select="handleSelect"
    >
      <template slot-scope="{ item }" v-if="component">
        <component :is="component" :item="item"></component>
      </template>
    </el-autocomplete>
  </div>
</template>
<script>
  import { handleComponent } from '../../../src/utils';

  export default {
    name: 'dog-autocomplete',
    props: {
      value: {
        default: undefined
      },
      options: {
        type: Array,
        default: () => []
      },
      service: {
        type: Function,
        default: () => {}
      },
      width: {
        type: Number,
        default: null
      },
      labelKey: {
        type: String,
        default: 'label'
      },
      valueKey: {
        type: String,
        default: 'value'
      },
      pageSize: {
        type: Number,
        default: 10
      }
    },
    data() {
      return {
        value_: '',
        value_cache: ''
      };
    },
    computed: {
      filterListeners() {
        return ['input'].reduce(
          (sum, key) => {
            delete sum[key];
            return sum;
          },
          { ...this.$listeners }
        );
      },
      component() {
        return handleComponent({
          component: this.$attrs.component,
          render: this.$attrs.render
        });
      }
    },
    mounted() {
      if (this.value) {
        this.api({
          [this.valueKey]: this.value,
          pageSize: this.pageSize,
          pageNo: 1
        }).then((res) => {
          let temp =
            res.find((item) => item[this.valueKey] === this.value) || {};
          this.value_ = temp[this.labelKey];
          this.value_cache = this.value_;
        });
      }
      this.$refs.autocomplete.$refs.suggestions.$on('visible', () => {
        this.value_ = this.value_cache;
      });
    },
    methods: {
      fetchSuggestions(val, cb) {
        this.api({
          [this.labelKey]: val,
          pageSize: this.pageSize,
          pageNo: 1
        }).then(cb);
      },
      api(val) {
        if (this.options.length > 0) {
          return val[this.valueKey]
            ? Promise.resolve(
                val[this.valueKey]
                  ? this.options.filter((item) =>
                      item[this.valueKey].includes(val[this.valueKey])
                    )
                  : this.options
              )
            : Promise.resolve(
                val[this.labelKey]
                  ? this.options.filter((item) =>
                      item[this.labelKey].includes(val[this.labelKey])
                    )
                  : this.options
              );
        } else {
          return this.service(val);
        }
      },
      handleSelect(val) {
        this.value_ = val[this.labelKey];
        this.value_cache = this.value_;
        this.$emit('input', val[this.valueKey]);
      }
    }
  };
</script>
<style lang="less" scoped>
  .el-autocomplete {
    width: 100%;
  }
</style>
