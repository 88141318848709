<template>
  <i class="svg-icon">
    <svg class="svg" aria-hidden="true">
      <use :xlink:href="xlinkHref"></use>
    </svg>
  </i>
</template>

<script>
  // const defaultAttrs = {
  //   size: 'small',
  // }
  export default {
    name: 'SketchIcon',
    props: {
      iconClass: {
        type: String,
        default: ''
      }
    },
    computed: {
      xlinkHref() {
        return `#${this.iconClass}`;
      }
    }
  };
</script>

<style lang="less" scoped>
  .svg-icon {
    display: flex;
    .svg {
      width: 1em;
      height: 1em;
      overflow: hidden;
      fill: currentColor;
    }
  }
</style>
