<template>
  <div class="ellipsis" :style="style()" v-bind="$attrs">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'ellipsis',
    props: {
      line: {
        type: String,
        default: '1'
      }
    },
    methods: {
      style() {
        if (this.line === '1') {
          return {
            overflow: 'hidden',
            'text-overflow': 'ellipsis',
            'white-space': 'nowrap'
          };
        } else {
          return {
            display: '-webkit-box',
            overflow: 'hidden',
            'text-overflow': 'ellipsis',
            '-webkit-line-clamp': this.line,
            '-webkit-box-orient': 'vertical'
          };
        }
      }
    }
  };
</script>

<style lang="less" scoped></style>
