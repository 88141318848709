<template>
  <el-radio-group
    :value="query"
    v-bind="$attrs"
    v-on="filterListeners"
    @input="change"
  >
    <el-radio
      v-for="(item, index) in options_"
      :disabled="item.disabled"
      :label="item.value"
      :key="item.label + item.value + index"
      >{{ item.label }}</el-radio
    >
  </el-radio-group>
</template>

<script>
  import { choice, getQuery } from '../../../src/mixins';

  export default {
    name: 'dog-radio',
    mixins: [choice, getQuery],
    props: {
      value: {
        default: undefined
      },
      service: {
        default: null
      },
      selectFirst: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        query: this.getDefaultQuery()
      };
    },
    computed: {
      filterListeners() {
        return ['input'].reduce(
          (sum, key) => {
            delete sum[key];
            return sum;
          },
          { ...this.$listeners }
        );
      }
    },
    mounted() {
      this.getOptions();
      if (!this.service) {
        this.getFirstValue();
      }
    },
    methods: {
      change(val) {
        if (this.value !== val) {
          this.$emit('input', val);
        }
      },
      getFirstValue() {
        if (this.selectFirst) {
          this.query = this.options_[0] ? this.options_[0].value : undefined;
        }
        this.change(this.query);
      },
      getOptions(val) {
        if (!this.service) return;
        this.optionsCache = [];
        return this.service(val).then((res) => {
          this.optionsCache = res;
          this.$nextTick(this.getFirstValue);
        });
      }
    },
    watch: {
      value() {
        this.query = this.value;
      }
    }
  };
</script>
