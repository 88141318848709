<template>
  <el-checkbox-group
    :value="query"
    v-bind="$attrs"
    v-on="filterListeners"
    @input="change"
  >
    <el-checkbox
      v-for="(item, index) in options_"
      v-bind="subAttrs"
      :disabled="item.disabled"
      :label="item.value"
      :key="item.label + item.value + index"
    >
      {{ item.label }}
    </el-checkbox>
  </el-checkbox-group>
</template>

<script>
  import { choice, getQuery } from '../../../src/mixins';

  export default {
    name: 'dog-checkbox',
    mixins: [choice, getQuery],
    props: {
      value: {
        type: Array,
        default: () => []
      },
      service: {
        default: null
      }
    },
    computed: {
      filterListeners() {
        return ['input'].reduce(
          (sum, key) => {
            delete sum[key];
            return sum;
          },
          { ...this.$listeners }
        );
      }
    },
    data() {
      return {
        query: this.getArrayDefaultQuery()
      };
    },
    mounted() {
      this.getOptions();
    },
    methods: {
      change(val) {
        this.$emit('input', val);
      },
      getOptions(val) {
        if (!this.service) return;
        this.optionsCache = [];
        return this.service(val).then((res) => {
          this.optionsCache = res;
        });
      }
    },
    watch: {
      value() {
        this.query = this.value;
      }
    }
  };
</script>
