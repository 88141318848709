import baseService from '../baseService';
const request = baseService('dictionary');

let enumMap = {};

export default function (code) {
  return () => {
    return enumMap[code]
      ? Promise.resolve(enumMap[code])
      : request('/getEnumList', { code }, 'get').then((res) => {
          enumMap[code] = res;
          return res;
        });
  };
}
