export default {
  path: '/exception',
  component: () =>
    import(/* webpackChunkName: "layout" */ '@/components/layout'),
  redirect: '/exception/404',
  children: [
    {
      name: '403',
      path: '/exception/403',
      component: () =>
        import(/* webpackChunkName: "403" */ '@/views/exception/403'),
      meta: {
        title: '403'
      }
    },
    {
      name: '500',
      path: '/exception/500',
      component: () =>
        import(/* webpackChunkName: "500" */ '@/views/exception/500'),
      meta: {
        title: '500'
      }
    },
    {
      name: '404',
      path: '/exception/404',
      component: () =>
        import(/* webpackChunkName: "404" */ '@/views/exception/404'),
      meta: {
        title: '404'
      }
    }
  ]
};
