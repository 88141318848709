export default {
  props: {
    default: {
      default: undefined
    }
  },
  methods: {
    getArrayDefaultQuery() {
      return Array.isArray(this.value) && this.value.length
        ? this.value
        : this.default || [];
    },
    getDefaultQuery() {
      return this.value !== undefined ? this.value : this.default;
    }
  }
};
