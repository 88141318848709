<template>
  <el-row class="dog-row" ref="dogRow" v-bind="$attrs" :style="style">
    <slot></slot>
  </el-row>
</template>

<script>
  import { resize } from '@/mixins';

  export default {
    name: 'dog-row',
    mixins: [resize],
    props: {
      height: {
        type: Number,
        default: null
      }
    },
    computed: {
      style() {
        let style = {
          height:
            this.height && !this.mixin_resize_containerIsMobile
              ? this.height + 'px'
              : '100%'
        };
        // 使用 flex 类型时，手机端改为 column 布局
        if (this.$attrs.type === 'flex') {
          style['flex-direction'] = this.mixin_resize_containerIsMobile
            ? 'column'
            : 'row';
        }
        if (this.$attrs.gutter) {
          // 纵向排列时的 gutter 也要修改
          if (this.mixin_resize_containerIsMobile) {
            style.marginLeft = '0';
            style.marginRight = '0';
            style.marginTop = `-${this.$attrs.gutter / 2}px`;
            style.marginBottom = style.marginTop;
          } else {
            style.marginTop = '0';
            style.marginBottom = '0';
            style.marginLeft = `-${this.$attrs.gutter / 2}px`;
            style.marginRight = style.marginLeft;
          }
        }
        return style;
      }
    }
  };
</script>

<style lang="less" scoped></style>
