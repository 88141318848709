<template>
  <div class="permission-button">
    <template v-for="(item, index) in showConfig_">
      <div
        v-if="item.component"
        :is="item.component"
        :item="item"
        :key="index"
      ></div>
      <template v-else>
        <dog-button
          v-if="item.loadingCallback"
          size="mini"
          :disabled="item.disabled"
          :type="item.buttonType"
          :icon="item.icon"
          @loading-click="item.loadingCallback($event, item)"
          :key="index"
        >
          <span v-if="item.name">{{ item.name }}</span>
        </dog-button>
        <dog-button
          v-else
          size="mini"
          :disabled="item.disabled"
          :type="item.buttonType"
          :icon="item.icon"
          @click="item.callback(item)"
          :loading="item.loading"
          :key="index"
        >
          <span v-if="item.name">{{ item.name }}</span>
        </dog-button>
      </template>
    </template>
    <el-dropdown
      ref="dropdown"
      class="indent"
      @command="handleCommand"
      v-show="moreConfig_.length > 0"
    >
      <dog-button size="mini" type="primary">
        更多<i class="el-icon-arrow-down el-icon--right"></i>
      </dog-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(item, index) in moreConfig_"
          :key="index"
          :icon="item.icon"
          :disabled="item.disabled"
          :command="index"
        >
          <div v-if="item.component" :is="item.component" :item="item"></div>
          <span v-else>{{ item.name }}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
  import mixin from './mixin';
  export default {
    name: 'permissionButton',
    mixins: [mixin]
  };
</script>

<style lang="less" scoped>
  .permission-button {
    display: inline-block;
    font-size: 0;
    .indent {
      margin-left: 10px;
    }
  }
</style>
