import main from './src/main';
// import wordsCount from './src/wordsCount';
const { Form, FormItem } = require('element-ui');

main.install = function (Vue) {
  Vue.component(main.name, main);
  // Vue.component(wordsCount.name, wordsCount);
  Vue.use(Form);
  Vue.use(FormItem);
};
export default main;
