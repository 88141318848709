<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
  export default {
    name: 'App'
  };
</script>
<style lang="scss">
  #app {
    position: relative;
    height: 100%;
    color: #2c3e50;
  }
</style>
