<template>
  <el-button v-on="filterListeners" v-bind="attrs" @click="click">
    <slot></slot>
  </el-button>
</template>

<script>
  const defaultAttrs = {
    size: 'small'
  };
  export default {
    name: 'dogButton',
    data() {
      return {
        isloading: false
      };
    },
    computed: {
      attrs() {
        return {
          ...defaultAttrs,
          ...this.$attrs,
          loading:
            this.$attrs.loading === undefined
              ? this.isloading
              : this.$attrs.loading
        };
      },
      filterListeners() {
        return ['click'].reduce(
          (sum, key) => {
            delete sum[key];
            return sum;
          },
          { ...this.$listeners }
        );
      }
    },
    methods: {
      click() {
        if (!this.$listeners['loading-click']) {
          this.$emit('click');
        } else {
          this.isloading = true;
          this.$emit('loading-click', () => {
            this.isloading = false;
          });
        }
      }
    }
  };
</script>

<style lang="less" scoped></style>
