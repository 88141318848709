import dayjs from 'dayjs';

// 转换驼峰
export function toHump(name) {
  return name.replace(/-(\w)/g, function (all, letter) {
    return letter.toUpperCase();
  });
}
// 获取时间控件的 shortcuts
export function getPickerShortcuts(type) {
  const getRange = (type, num, unit = 'day') => {
    let end = new Date(dayjs().add(1, unit).format('YYYY/MM/DD'));
    let start = new Date(dayjs(end).subtract(num, unit).valueOf());
    if (type === 'daterange') {
      end = dayjs(end).subtract(1, 'day');
    }
    return [start, end];
  };
  return [
    {
      text: '今天',
      onClick(picker) {
        picker.$emit('pick', getRange(type, 1));
      }
    },
    {
      text: '昨日',
      onClick(picker) {
        let start = new Date(dayjs().subtract(1, 'day').format('YYYY/MM/DD'));
        let end = new Date(dayjs().format('YYYY/MM/DD'));
        if (type === 'daterange') {
          end = dayjs(end).subtract(1, 'day');
        }
        picker.$emit('pick', [start, end]);
      }
    },
    {
      text: '近7天',
      onClick(picker) {
        picker.$emit('pick', getRange(type, 7));
      }
    },
    {
      text: '近30天',
      onClick(picker) {
        picker.$emit('pick', getRange(type, 30));
      }
    },
    {
      text: '近90天',
      onClick(picker) {
        picker.$emit('pick', getRange(type, 90));
      }
    }
  ];
}
export function getDatePickerShortcuts() {
  return [
    {
      text: '今天',
      onClick(picker) {
        picker.$emit('pick', new Date());
      }
    },
    {
      text: '昨天',
      onClick(picker) {
        const date = new Date();
        date.setTime(date.getTime() - 3600 * 1000 * 24);
        picker.$emit('pick', date);
      }
    },
    {
      text: '一周前',
      onClick(picker) {
        const date = new Date();
        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', date);
      }
    }
  ];
}
