import Vue from 'vue';
import ELEMENT from 'element-ui';
import directives from './directives';
import filters from './filters';
import { listenerPolicy } from './mixins';
import enumService from './api/service/enum';
import enumMap from './utils/constant/enum';
import regexp from './utils/constant/regexp';
import formRules from './utils/constant/formRules';
import methods from './utils/methods';
import Subject from './utils/internalLogic/Subject';
import EventBus from './utils/internalLogic/EventBus';
import dogweb from './lib/dogweb/src';
import './utils/internalLogic/Resize';
import 'normalize.css/normalize.css';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/styles/index.scss';
import './assets/svg-icons';


window.Subject = new Subject();
window.EventBus = EventBus;

Vue.use(ELEMENT);
Vue.use(dogweb);
Vue.use(directives);
Vue.use(filters);

Vue.mixin(listenerPolicy);

Vue.prototype.$enumService = enumService;
Vue.prototype.$enum = enumMap;
Vue.prototype.$regexp = regexp;
Vue.prototype.$formRules = formRules;
Vue.prototype.$methods = methods;

export default Vue;
