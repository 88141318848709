import containerSummary from '../package/container-summary';
import controlsSummary from '../package/controls-summary';
import dogDialog from '../package/dogDialog';
import dogForm from '../package/dogForm';
import baseTable from '../package/baseTable';
import dogTable from '../package/dogTable';
import dogSearch from '../package/dogSearch';
import dogButton from '../package/dogButton';
import svgIcon from '../package/svgIcon';
import permissionButton from '../package/permissionButton';
import permissionElement from '../package/permissionElement';
import contextMenu from '../package/contextMenu';
import ellipsis from '../package/ellipsis';
import dogCollapseTransition from './transitions/collapse-transition';
// import { version } from '../package.json';

const components = {
  ...containerSummary,
  ...controlsSummary,
  baseTable,
  dogTable,
  dogSearch,
  dogButton,
  svgIcon,
  permissionButton,
  permissionElement,
  dogDialog,
  dogForm,
  dogCollapseTransition,
  ellipsis
};
const install = function (Vue) {
  Object.keys(components).forEach((key) => {
    Vue.component(key, components[key]);
    Vue.prototype.$contextMenu = contextMenu;
  });
};

/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

export default {
  // version,
  version: '0.1.0',
  install,
  ...components
};
