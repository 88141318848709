<template>
  <dog-dialog
    ref="dogDialog"
    width="550px"
    title="自定义列配置"
    @close="close"
    @closed="closed"
    @confirm="confirm"
  >
    <base-table
      ref="baseTable"
      is-draggable
      :sortable-options="{
        filter: '.divider',
        preventOnFilter: false
      }"
      :data="tableData"
      :columns="tableColumns"
      :show-tool="false"
      @drap-end="handleDrag"
    >
    </base-table>
    <div slot="footer">
      <dog-button type="primary" @click="confirm">保 存</dog-button>
      <dog-button @click="close">关 闭</dog-button>
      <dog-button @click="restoreDefault">恢复默认</dog-button>
    </div>
  </dog-dialog>
</template>

<script>
  import { deepClone } from '@/utils/methods/common';

  export default {
    name: 'customColumns',
    props: {
      value: {
        type: Array,
        default: () => []
      },
      columnsOptions: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        tableData: [],
        tableColumns: [
          {
            label: '勾选需要显示的列，拖动列名进行排序(固定列暂时不开放排序)。',
            prop: 'label',
            render: (h, { props: { row } }) => {
              let dividerStyle = {
                'font-size': '12px',
                'line-height': '30px',
                'text-align': 'center',
                color: '#c0c4cc'
              };
              if (row.divider === 'left')
                return (
                  <div style={dividerStyle} class='divider'>
                    拖动到上面为左悬浮列
                  </div>
                );
              if (row.divider === 'right')
                return (
                  <div style={dividerStyle} class='divider'>
                    拖动到底部为右悬浮列
                  </div>
                );
              const change = (key, val) => {
                this.$set(row, key, val);
                this.handleDrag();
              };
              row.widthGrow =
                row.widthGrow === undefined ? true : row.widthGrow;
              return (
                <div
                  style='display: flex; align-items: center;'
                  class={row.prop === 'operate' ? 'divider' : ''}>
                  <ellipsis style='flex-grow: 1;' title={row.label}>
                    {row.prop === 'operate' ? null : (
                      <el-checkbox
                        style='margin-right: 6px'
                        value={!row.filter}
                        on-input={(val) =>
                          change('filter', !val)
                        }></el-checkbox>
                    )}
                    {row.label}
                  </ellipsis>
                  <div style='flex-shrink: 0;'>
                    <el-checkbox
                      style='margin: 0 10px'
                      checked={row.widthGrow}
                      onChange={(val) => change('widthGrow', val)}>
                      自适应
                    </el-checkbox>
                    <span>宽度</span>
                    <el-input-number
                      style={{
                        margin: '0 5px',
                        color: row.widthGrow ? 'red' : ''
                      }}
                      precision={0}
                      step={10}
                      size='mini'
                      min={0}
                      max={9999}
                      width={60}
                      placeholder='列宽'
                      value={row.width}
                      onInput={(val) => change('width', val)}></el-input-number>
                    <span>px</span>
                  </div>
                </div>
              );
            }
          }
        ],
        columnsCache: [],
        customColumns: [],
        leftFixedColumn: [],
        rightFixedColumn: []
      };
    },
    methods: {
      openFrame(columns, columnsCache) {
        this.columnsCache = columnsCache.map((item) => ({
          ...item,
          width: ['', undefined, null].includes(item.width)
            ? this.$parent.minColumnWidth
            : item.width
        }));
        this.$refs.dogDialog.open();
        this.handleColumns(
          columns.map((item) => ({
            ...item,
            width: ['', undefined, null].includes(item.width)
              ? this.$parent.minColumnWidth
              : item.width
          }))
        );
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.customColumns = [];
      },
      confirm() {
        this.$emit('custom-columns', this.customColumns);
        this.close();
      },
      changeDividerStyle() {
        this.$nextTick(() => {
          Array.from(this.$el.querySelectorAll('.divider')).forEach((node) => {
            Object.assign(node.parentNode.style, {
              padding: '0',
              width: '100%'
            });
            Object.assign(node.parentNode.parentNode.style, {
              padding: '0',
              margin: '0',
              'box-shadow': '0px -1px 0px #ebeef5 inset',
              border: 0
            });
          });
        });
      },
      handleColumns(columnsOptions) {
        this.tableData = deepClone(columnsOptions);
        this.leftFixedColumn = this.tableData.filter(
          (item) => item.fixed === 'left'
        );
        this.rightFixedColumn = this.tableData.filter(
          (item) => item.fixed === 'right'
        );
        this.tableData = this.tableData.filter((item) => !item.fixed);
        this.tableData = [].concat(
          this.leftFixedColumn,
          { divider: 'left' },
          this.tableData,
          { divider: 'right' },
          this.rightFixedColumn
        );
        this.handleDrag();
        this.changeDividerStyle();
      },
      restoreDefault() {
        this.handleColumns(this.columnsCache);
      },
      handleDrag() {
        let temp = [[]];
        this.tableData.forEach((item) => {
          let last = temp[temp.length - 1];
          if (item.divider) {
            temp.push([]);
          } else {
            last.push(item);
          }
        });
        temp[0] = temp[0].map((item) => ({ ...item, fixed: 'left' }));
        temp[1].forEach((item) => delete item.fixed);
        temp[2] = temp[2].map((item) => ({ ...item, fixed: 'right' }));
        this.customColumns = temp.reduce((sum, item) => sum.concat(item));
      }
    }
  };
</script>
