import Sortable from 'sortablejs';

export default {
  mounted() {
    this.$el.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    };
    this.$nextTick(() => {
      this.isDraggable && this.rowDrap();
    });
  },
  props: {
    isDraggable: {
      type: Boolean,
      default: false
    },
    sortableOptions: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    getRowKey(row) {
      return row.__ob__.dep.id;
    },
    rowDrap() {
      const tbody = this.$el.querySelector('.el-table__body-wrapper tbody');
      Sortable.create(tbody, {
        animation: 180,
        onEnd: (evt) => {
          let from = [...this.showData];
          const currRow = this.showData.splice(evt.oldIndex, 1)[0];
          this.showData.splice(evt.newIndex, 0, currRow);
          this.$emit('drap-end', {
            row: currRow,
            oldIndex: evt.oldIndex,
            newIndex: evt.newIndex,
            to: this.showData,
            from
          });
        },
        ...this.sortableOptions
      });
    }
  }
};
