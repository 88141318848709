import controls from '../../package/controls-summary/index';
import { handleComponent } from '../utils';

const DEFAULT_TYPE_MAP = {
  input: 'dog-input',
  autocomplete: 'dog-autocomplete',
  select: 'dog-select',
  date: 'dog-date',
  daterange: 'dog-date-range',
  datetime: 'dog-date-time',
  datetimerange: 'dog-date-time-range',
  checkbox: 'dog-checkbox',
  radio: 'dog-radio',
  switch: 'dog-switch'
};
// function handleComponent(obj) {
//   let {component, render} = obj;
//   if (render) {
//     return {
//       functional: true,
//       render
//     };
//   } else {
//     return component;
//   }
// }
export default {
  props: {
    typeMap: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    _typeMap() {
      return Object.assign({}, DEFAULT_TYPE_MAP, this.typeMap);
    }
  },
  methods: {
    getComponent(obj = {}) {
      let { type } = obj;
      return (
        (type === 'custom' && handleComponent(obj)) ||
        this._typeMap[type] ||
        type
      );
    },
    isExistType(type) {
      return Boolean(this._typeMap[type]);
    }
  },
  components: {
    ...controls
  }
};
