<template>
  <div
    :class="['context-menu', { overthrow: location.overthrow }]"
    @contextmenu.prevent
    :style="{
      ...style,
      left: location.x + 'px',
      top: location.y + 'px'
    }"
    v-clickoutside="clickoutside"
  >
    <div
      class="context-menu-item"
      v-for="item in config"
      :key="item.callback"
      @click="clickItem(item)"
    >
      {{ item.label }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'context-menu',
    data() {
      return {
        config: [],
        data: {},
        location: {
          x: '',
          y: '',
          overthrow: false
        },
        style: {}
      };
    },
    methods: {
      clickItem(item) {
        item.callback(this.data);
        this.clickoutside();
      },
      clickoutside() {
        this.$destroy();
        document.body.removeChild(this.$el);
      }
    }
  };
</script>

<style lang="less" scoped>
  .context-menu {
    position: absolute;
    z-index: 6000;
    overflow: hidden;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0.5em 1em 0 rgba(0, 0, 0, 0.1);
    &-item {
      padding: 0 10px;
      cursor: pointer;
      &:hover {
        color: white;
        background-color: #409eff;
      }
    }
    &.overthrow {
      transform: translateY(-100%);
    }
  }
</style>
