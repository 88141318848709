const LIMIT = 500; // 移动端像素分界线

export default {
  data() {
    return {
      mixin_resize_windowIsMobile: false,
      mixin_resize_windowWidth: 0,
      mixin_resize_containerWidth: 0,
      mixin_resize_containerIsMobile: false,
      mixin_resize_container: ''
    };
  },
  watch: {
    mixin_resize_container(newVal, oldVal) {
      if (oldVal) {
        this.mixin_listenerPolicy_removeListener(
          oldVal,
          'resizeObserver',
          this.mixin_resize_containerResize
        );
      }
      if (newVal) {
        this.mixin_listenerPolicy_addListener(
          newVal,
          'resizeObserver',
          this.mixin_resize_containerResize
        );
      }
    }
  },
  mounted() {
    this.mixin_resize_container = this.$el.parentElement;
    window.EventBus.$on('resize', ({ mixin_resize_windowWidth }) => {
      this.mixin_resize_windowWidth = mixin_resize_windowWidth;
      this.mixin_resize_windowIsMobile = mixin_resize_windowWidth < LIMIT;
    });
    window.EventBus.$emit('getResizeInfo');
  },
  methods: {
    mixin_resize_containerResize() {
      this.mixin_resize_containerWidth = this.mixin_resize_container.clientWidth;
      this.mixin_resize_containerIsMobile =
        this.mixin_resize_containerWidth < LIMIT;
    }
  }
};
