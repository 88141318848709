import baseService from '../baseService';
import enumService from './enum';
import { array_find } from '@/utils/methods/common';
const request = baseService('user');

export default {
  register(params) {
    return request('/register', params, 'post');
  },
  login(params) {
    return request('/login', params, 'post');
  },
  editPassword(params) {
    return request('/editPassword', params, 'put');
  },
  editEmail(params) {
    return request('/editEmail', params, 'put');
  },
  getUserInfo(params) {
    return request('/getUserInfo', params, 'get');
  },
  getUserList(params) {
    return Promise.all([
      request('/getUserList', params, 'get'),
      enumService('sex')()
    ]).then((res) => {
      res[0].list.forEach((item) => {
        item.sex_cn = array_find(res[1], 'value', item.sex).label;
      });
      return res[0];
    });
  },
  getUserDetail(params) {
    return request('/getUserDetail', params, 'get');
  },
  getUserRoleIds(params) {
    return request('/getUserRoleIds', params, 'get');
  },
  setUserRoleIds(params) {
    return request('/setUserRoleIds', params, 'post');
  },
  editUser(params) {
    return request('/editUser', params, 'put');
  },
  deleteUsers(params) {
    return request('/deleteUsers', params, 'delete');
  },
  editPasswordByAdmin(params) {
    return request('/editPasswordByAdmin', params, 'put');
  },
  editPasswordByEmail(params) {
    return request('/editPasswordByEmail', params, 'put');
  }
};
