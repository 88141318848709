<template>
  <div class="permission-element">
    <template v-for="(item, index) in showConfig_">
      <div
        v-if="item.component"
        :is="item.component"
        :item="item"
        :key="index"
      ></div>
      <el-link
        v-else
        :underline="false"
        :disabled="item.disabled"
        :type="item.buttonType || 'primary'"
        :icon="item.icon"
        @click="item.callback(item)"
        :key="index"
        >{{ item.name }}</el-link
      >
      <!-- <el-divider
        direction="vertical"
        v-if="index < showConfig_.length - 1"
      ></el-divider> -->
    </template>
    <el-dropdown
      ref="dropdown"
      class="indent"
      @command="handleCommand"
      v-show="moreConfig_.length > 0"
    >
      <el-link type="primary" :underline="false">
        <span>更多</span>
        <i class="el-icon-arrow-down el-icon--right"></i>
      </el-link>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(item, index) in moreConfig_"
          :key="index"
          :icon="item.icon"
          :disabled="item.disabled"
          :command="index"
        >
          <div v-if="item.component" :is="item.component" :item="item"></div>
          <span v-else>{{ item.name }}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
  import mixin from '../permissionButton/mixin';
  export default {
    name: 'permissionElement',
    mixins: [mixin]
  };
</script>

<style lang="less" scoped>
  .permission-element {
    display: flex;
    align-items: center;
    .indent {
      margin-left: 8px;
      padding-left: 8px;
      border-left: 1px solid #dcdfe6;
      // line-height: 14px;
    }
  }
  /deep/ .el-link {
    box-sizing: border-box;
    white-space: nowrap;
    .el-link--inner {
      margin-left: 0;
      line-height: 23px;
    }
    i:first-child {
      padding-right: 5px;
      line-height: 23px;
    }
    &:not(.is-disabled) {
      .el-link--inner,
      i:first-child {
        border-bottom: 1px solid transparent;
      }
    }
    &:not(.is-disabled):hover {
      .el-link--inner,
      i:first-child {
        border-bottom: 1px solid;
      }
    }
  }
  .el-link + .el-link {
    margin-left: 8px;
    padding-left: 8px;
    border-left: 1px solid #dcdfe6;
    // line-height: 14px;
  }
</style>
