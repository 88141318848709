import dogCheckbox from './dogCheckbox';
import dogInput from './dogInput';
import dogAutocomplete from './dogAutocomplete';
import dogRadio from './dogRadio';
import dogSelect from './dogSelect';
import dogSwitch from './dogSwitch';
import dogDate from './dogDate';
import dogDateRange from './dogDateRange';
import dogDateTime from './dogDateTime';
import dogDateTimeRange from './dogDateTimeRange';
export default {
  dogCheckbox,
  dogInput,
  dogAutocomplete,
  dogRadio,
  dogSelect,
  dogSwitch,
  dogDate,
  dogDateRange,
  dogDateTime,
  dogDateTimeRange
};
