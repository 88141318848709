<template>
  <dog-row
    class="dog-table"
    :class="{ isMobile: mixin_resize_containerIsMobile }"
  >
    <dog-row class="dog-table-top">
      <dog-col
        class="left"
        v-if="$slots.btn || !mixin_resize_containerIsMobile"
        :height="32"
      >
        <slot name="btn"></slot>
      </dog-col>
      <dog-col class="right" v-if="!lazyLoad && hasPaging" :height="32">
        <dog-paging
          :total="total"
          :page-no="pageNo"
          :page-size="pageSize"
          :page-size-list="pageSizeList"
          @page-change="pageChange"
          @size-change="sizeChange"
        >
        </dog-paging>
      </dog-col>
    </dog-row>
    <div class="dog-table-bottom">
      <div class="dog-table-bottom-content">
        <base-table
          ref="baseTable"
          height="100%"
          v-bind="{ ...$attrs, lazyLoad }"
          @total-change="total = $event"
          v-on="$listeners"
        >
        </base-table>
      </div>
    </div>
  </dog-row>
</template>

<script>
  import baseTable from '../../baseTable';
  import dogPaging from '../../dogPaging';
  import { resize } from '@/mixins';

  const DEFAULT_PAGESIZE = 15;
  const DEFAULT_PAGENO = 1;
  export default {
    name: 'dogTable',
    components: {
      baseTable,
      dogPaging
    },
    mixins: [resize],
    props: {
      pageSizeList: {
        type: Array,
        default: () => [20, 30, 50, 100, 200]
      },
      hasPaging: {
        type: Boolean,
        default: true
      },
      lazyLoad: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      query() {
        return this.$refs.baseTable.query || {};
      }
    },
    data() {
      return {
        total: 0,
        pageNo: DEFAULT_PAGENO,
        pageSize:
          (this.pageSizeList && this.pageSizeList[0]) || DEFAULT_PAGESIZE
      };
    },
    methods: {
      pageChange(pageNo, ...argv) {
        this.pageNo = pageNo;
        this.updateData(...argv);
      },
      sizeChange(pageSize) {
        this.pageSize = pageSize;
        this.pageChange(1);
      },
      search(query, firstPage = true) {
        if (firstPage) {
          this.pageChange(1, query, 'search');
        } else {
          this.pageChange(this.pageNo, query, 'search');
        }
      },
      updateData(query, type) {
        if (!this.staticPaging) {
          this.$refs.baseTable.getTableData(
            {
              ...(query || this.query),
              pageNo: this.pageNo,
              pageSize: this.pageSize
            },
            type
          );
        }
      }
    }
  };
</script>
<style lang="less">
  .dog-table {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    &-top {
      // display: flex;
      // flex-shrink: 0;
      // align-items: center;
      // height: 32px;
      display: flex;
      flex-basis: 0;
      .left {
        flex-grow: 1;
        flex-shrink: 2;
        width: auto;
        height: 100%;
        min-width: 100px;
        overflow: auto;
        white-space: nowrap;
      }
      .right {
        flex-shrink: 0;
        width: auto;
        height: 100%;
        // overflow: auto;
        text-align: right;
      }
    }
    &-bottom {
      position: relative;
      flex-grow: 1;
      flex-shrink: 0;
      &-content {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }
  .isMobile {
    .dog-table-top {
      flex-direction: column;
      .right {
        text-align: left;
      }
    }
  }
</style>
