<template>
  <div class="svg-icon">
    <svg class="svg" aria-hidden="true">
      <use :xlink:href="xlinkHref"></use>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SketchIcon',
  props: {
    iconfont: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    xlinkHref() {
      return this.iconfont ? `#${this.iconClass}` : `#local-${this.iconClass}`;
    },
  },
};
</script>

<style lang="less" scoped>
.svg-icon {
  display: flex;
  height: 100%;
  .svg {
    width: 100%;
    height: 100%;
    overflow: hidden;
    fill: currentColor;
  }
}
</style>
