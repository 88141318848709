var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dog-form"},[_c('el-form',_vm._b({ref:"ruleForm",attrs:{"model":_vm.formData},nativeOn:{"submit":function($event){$event.preventDefault();}}},'el-form',_vm.bindForm(),false),[_c('dog-grid-box',_vm._b({attrs:{"data":_vm.formOptions},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('el-form-item',_vm._b({key:Array.isArray(item.formItem.prop)
              ? item.formItem.prop[0]
              : item.formItem.prop,class:_vm.getItemClass(item)},'el-form-item',Object.assign({}, item.formItem,
            {prop: Array.isArray(item.formItem.prop)
              ? item.formItem.prop[0]
              : item.formItem.prop}),false),[(item.type)?[(!(item.computed || item.formatter))?_c(_vm.getComponent(item),_vm._g(_vm._b({ref:_vm.getKey(item),tag:"component",attrs:{"value":_vm.getValueByPath_(_vm.formData, item.formItem.prop)},on:{"input":function($event){return _vm.setValueByPath(_vm.formData, item.formItem.prop, $event)}}},'component',_vm.getBind(item),false),_vm.listenersMap[_vm.getKey(item)])):_c(_vm.getComponent(item),_vm._g(_vm._b({ref:_vm.getKey(item),tag:"component",attrs:{"value":_vm.valueExtend(item)}},'component',_vm.getBind(item),false),_vm.listenersMap[_vm.getKey(item)]))]:_c('span',{staticClass:"dog-form_text"},[_vm._v(_vm._s(_vm.valueExtend(item)))])],2)]}}])},'dog-grid-box',_vm.$attrs,false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }