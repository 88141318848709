<template>
  <el-col class="dog-col" ref="dogCol" v-bind="getBind" :style="style">
    <slot></slot>
  </el-col>
</template>

<script>
  import { resize } from '@/mixins';

  export default {
    name: 'dog-col',
    mixins: [resize],
    props: {
      height: {
        type: Number,
        default: null
      }
    },
    computed: {
      gutter() {
        let parentNode = this.$parent;
        while (parentNode && parentNode.$options.name !== 'dog-row') {
          parentNode = parentNode.$parent;
        }
        return parentNode ? parentNode.$attrs.gutter : 0;
      },
      style() {
        let style = {};
        if (this.mixin_resize_containerIsMobile) {
          style.height = this.height ? this.height + 'px' : 'auto';
          // 纵向排列时的 gutter 也要修改
          if (this.gutter) {
            style.paddingLeft = '0';
            style.paddingRight = '0';
            style.paddingTop = this.gutter / 2 + 'px';
            style.paddingBottom = style.paddingTop;
          }
        } else {
          if (this.gutter) {
            style.paddingTop = '0';
            style.paddingBottom = '0';
            style.paddingLeft = this.gutter / 2 + 'px';
            style.paddingRight = style.paddingLeft;
          }
          style.height = '100%';
        }
        return style;
      },
      getBind() {
        if (this.mixin_resize_containerIsMobile) {
          return { ...this.$attrs, pull: 0, push: 0, offset: 0, span: 24 };
        } else {
          return this.$attrs;
        }
      }
    }
  };
</script>
