let defaultKeyMap = {
  label: 'label',
  value: 'value'
};
export default {
  props: {
    keyMap: {
      type: Object,
      default: () => ({
        label: 'label',
        value: 'value'
      })
    },
    options: {
      type: Array,
      default: () => []
    },
    subAttrs: {
      default: () => ({})
    },
    bindObject: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      optionsCache: this.options
    };
  },
  watch: {
    options() {
      this.optionsCache = this.options;
    }
  },
  computed: {
    keyMap_() {
      return { ...defaultKeyMap, ...this.keyMap };
    },
    options_() {
      return this.optionsCache.map((item) => {
        let label = item[this.keyMap_.label];
        let value = item[this.keyMap_.value];
        return {
          ...item,
          label:
            label !== undefined ? label : value !== undefined ? value : item,
          value:
            value !== undefined ? value : label !== undefined ? label : item
        };
      });
    }
  }
};
