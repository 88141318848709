<template>
  <div class="base-table">
    <el-table
      ref="elTable"
      :key="key"
      highlight-current-row
      :row-key="
        isDraggable ? getRowKey : this.$attrs.rowKey || this.$attrs['row-key']
      "
      :data="showData"
      :cell-style="cellStyle"
      :span-method="spanMethod_()"
      v-bind="tableBind"
      v-loading="loading"
      v-on="$listeners"
      @selection-change="selectionChange"
      @sort-change="sortChange"
    >
      <loop-column
        v-for="(tp, index) in typesColumns"
        :key="tp.type + '-' + index"
        :col="tp.col"
        :type="tp.type"
      ></loop-column>
      <loop-column
        v-for="col in columns_"
        :key="col.prop"
        :col="col"
      ></loop-column>
    </el-table>
    <div ref="progress" class="animation" v-show="isLoadingData">
      <div class="tab"></div>
    </div>
    <custom-columns
      ref="customColumns"
      @custom-columns="customColumns"
    ></custom-columns>
  </div>
</template>
<script>
  import { handleComponent } from '../../../src/utils';
  import service from './mixins/service';
  import order from './mixins/order';
  import typeColumns from './mixins/typeColumns';
  import render from './mixins/render';
  import drag from './mixins/drag';
  import loopColumn from './loop-column';
  import customColumns from './customColumns';
  import { exportTableDataExcel } from './utils/excel';

  const DEFAULT_TABLE_ATTRS = {
    stripe: true
  };
  export default {
    name: 'baseTable',
    components: {
      loopColumn,
      customColumns
    },
    provide() {
      return {
        baseTable: this
      };
    },
    props: {
      id: {
        type: String,
        default: null
      },
      minColumnWidth: {
        type: Number,
        default: 120
      },
      //是否有选择框
      selection: {
        type: String,
        default: ''
      },
      //表格头部配置
      columns: {
        type: Array,
        default: () => []
      },
      data: {
        type: Array,
        default: null
      },
      staticPaging: {
        type: Boolean,
        default: false
      },
      spanMethod: {
        type: Function,
        default: () => {}
      },
      loading: {
        type: Boolean,
        default: false
      },
      showTool: {
        type: Boolean,
        default: true
      }
    },
    mixins: [service, order, typeColumns, render, drag],
    computed: {
      realTotal() {
        return this.staticPaging
          ? this.staticDataCache
            ? this.staticDataCache.length
            : 0
          : this.total;
      },
      showData() {
        if (this.data) return this.data;
        return this.staticPaging ? this.staticData : this.tableData;
      },
      tableBind() {
        return { ...DEFAULT_TABLE_ATTRS, ...this.$attrs };
      }
    },
    data() {
      return {
        total: 0,
        tableData: [],
        staticData: [],
        staticDataCache: [],
        key: 0,
        columns_: [],
        headerWrapper: null,
        columnsCache: [],
        toolWidth: 35
      };
    },
    watch: {
      realTotal() {
        this.$emit('total-change', this.realTotal);
      }
    },
    mounted() {
      if (this.id) {
        this.$el
          .querySelector('.el-table__header-wrapper')
          .addEventListener('contextmenu', (event) => {
            event.preventDefault();
          });
      } else {
        if (!this.id && !this.isDraggable) {
          // console.log(`table 未配置 id 列配置不会缓存 ${this.$route.path}`);
        }
      }
    },
    methods: {
      selectionChange() {
        this.$nextTick(() => {
          this.$refs.elTable.$refs.fixedBodyWrapper.scrollTop = this.$refs.elTable.$refs.bodyWrapper.scrollTop;
        });
      },
      openColumnsSetting() {
        this.$refs.customColumns.openFrame(
          this.columnsCache,
          this._dealedColumns
        );
      },
      spanMethod_() {
        return this.spanMethod(this.showData);
      },
      loopColumns(columns = []) {
        if (columns.length === 0) return [];
        return columns.map((col) => {
          col.component = handleComponent(col);
          col.merge = this.loopColumns(col.merge);
          return col;
        });
      },
      cellStyle() {
        return {
          padding: '6px 0'
        };
      },
      exportTableData({
        tableData,
        query = {},
        useCacheColumns,
        ...options
      } = {}) {
        const getTableData = tableData
          ? () => {
              return Promise.resolve(tableData);
            }
          : () => {
              return this.service(query).then((res) => {
                return res.list;
              });
            };
        return getTableData().then((tableData) => {
          const tableColumns = (() => {
            if (!useCacheColumns) {
              return this._dealedColumns;
            }
            const cache = localStorage.getItem('tableColumnsCache')
              ? JSON.parse(localStorage.getItem('tableColumnsCache'))
              : {};
            return cache[this.id] || this._dealedColumns;
          })();
          exportTableDataExcel(tableColumns, tableData, options);
        });
      }
    }
  };
</script>
<style lang="less" scoped>
  @keyframes progress {
    0% {
      transform: translateX(-30px) scaleX(1);
    }
    50% {
      transform: translateX(calc(~'50% - 15px')) scaleX(2);
    }
    100% {
      transform: translateX(100%) scaleX(1);
    }
  }
  .base-table {
    position: relative;
    height: 100%;
    .set-button {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }
    .animation {
      position: absolute;
      bottom: 0;
      z-index: 5;
      width: 100%;
      height: 2px;
      overflow: hidden;
      .tab {
        height: 100%;
        transform-origin: left;
        background-image: linear-gradient(
          90deg,
          #409eff 0,
          #409eff 30px,
          transparent 30px
        );
        animation: progress 2s infinite linear;
      }
    }
  }
  /deep/.el-table {
    .el-table__header {
      .el-table-column--selection {
        .cell {
          padding-right: 0;
          padding-left: 14px;
        }
      }
    }
    .divider {
      padding: 6px 10px;
    }
  }
</style>
