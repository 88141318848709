export default {
  data() {
    return {
      dropDownFlag: true, // 是否折叠
      columnsNumber: 6, // 实时显示的列数
      normalItemWidth: 320,
      normalItemHeight: 44,
      minItemWidth: 320,
      showData: [],
      buttonPoint: {
        x: 0,
        y: 0
      }
    };
  },
  watch: {
    config() {
      this.showData = [];
      this.$nextTick(() => {
        this.init();
      });
    },
    dropDownFlag() {
      this.countCoordinate();
    },
    showData: {
      handler: function () {
        this.countCoordinate();
      },
      deep: true
    },
    expandAll: {
      handler(newVal) {
        this.dropDownFlag = !newVal;
      },
      immediate: true
    }
  },
  activated() {
    this.resizeObserver(); //解决被缓存的子页面在切换回来时search宽度缩水的问题
  },
  mounted() {
    this.init();
  },
  computed: {
    configLength() {
      return this.showData
        .filter((item) => !item.invisible)
        .reduce((sum, item) => {
          return sum + (this.isLongItem(item) ? 2 : 1);
        }, 0);
    },
    longItemWidth() {
      return this.normalItemWidth * 2;
    },
    conditionMinWidth() {
      return this.minItemWidth * 2 + 'px';
    }
  },
  methods: {
    isLongItem(item) {
      if (item.type === 'datetimerange') {
        return true;
      }
      if (item.type === 'custom' && item.size === 'long') {
        return true;
      }
      return false;
    },
    clickOutSide() {
      !this.expandAll && (this.dropDownFlag = true);
    },
    resizeObserver() {
      const contentStyle = getComputedStyle(this.$refs.searchContent);
      let clientWidth =
        this.$refs.searchContent.clientWidth -
        parseFloat(contentStyle.paddingLeft) -
        parseFloat(contentStyle.paddingRight);
      // let clientWidth = this.$refs.searchcondition.clientWidth;
      this.columnsNumber = Math.floor(clientWidth / this.minItemWidth);
      this.normalItemWidth = Math.floor(clientWidth / this.columnsNumber);
      this.countCoordinate();
    },
    countCoordinate() {
      this.countCoordinateForPc();
    },
    countCoordinateForPc() {
      this.shellFuncForPc((config, point) => {
        let tempNum = !this.dropDownFlag
          ? this.columnsNumber
          : this.columnsNumber - 1;
        let index = -1;
        if (point.x + 2 <= tempNum) {
          index = config.findIndex((item) => item.isLong);
        }
        if (index < 0 && point.x + 1 <= tempNum) {
          index = config.findIndex((item) => !item.isLong);
        }
        if (index >= 0) {
          let ket = config[index];
          ket.x = point.x;
          ket.y = point.y;
          ket.isCalulate = true;
          config.splice(index, 1);
          point.x += ket.isLong ? 2 : 1;
          if (point.x === this.columnsNumber) {
            point.x = 0;
            point.y += 1;
          }
        } else {
          if (!this.dropDownFlag) {
            point.x = 0;
            point.y += 1;
          } else {
            point.x = this.columnsNumber - 1;
          }
        }
      });
    },
    shellFuncForPc(countMethod) {
      this.showData.forEach((item) => {
        item.isCalulate = false;
      });
      let tempConfig = this.showData.filter((item) => !item.invisible);
      let point = { x: 0, y: 0 };
      if (!this.dropDownFlag) {
        while (tempConfig.length > 0) {
          countMethod(tempConfig, point);
        }
      } else {
        while (tempConfig.length > 0 && point.x < this.columnsNumber - 1) {
          countMethod(tempConfig, point);
        }
      }
      this.showData.forEach((item) => {
        if (item.invisible || item.isCalulate === false) {
          // item.x = -1;
          item.y = -1;
        }
      });
      this.buttonPoint.x = this.columnsNumber - 1;
      this.buttonPoint.y = point.y;
    },
    init() {
      this.showData = this.config.map((item) => {
        return Object.assign(item, {
          isLong: this.isLongItem(item)
        });
      });
      this.getBaseQuery();
      // this.resizeObserver();
      // this.mixin_listenerPolicy_addListener(this.$refs.search, 'resizeObserver', this.resizeObserver);
    }
  }
};
