import EventBus from './EventBus';

const resize = () => {
  const width = window.innerWidth || document.body.clientWidth;
  EventBus.$emit('resize', {
    mixin_resize_windowWidth: width
  });
};

EventBus.$on('getResizeInfo', resize);

window.addEventListener('resize', resize, false);
