import { CustomStore } from './utils';
import user from './modules/user';
import menu from './modules/menu';

export default new CustomStore({
  modules: {
    user,
    menu
  }
});
