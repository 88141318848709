/**
 * 变量转boolean
 * @param value
 * @returns {boolean}
 */
export function coerceBoolean(value) {
  return (
    value !== null &&
    value !== undefined &&
    `${value}` !== 'false' &&
    `${value}` !== 'NaN' &&
    `${value}` !== ''
  );
}
/**
 * 字符串数字转 boolean
 * @param {string} stringNum 字符串数字
 * @return {boolean} true/false
 */
export function stringNumToBoolean(stringNum) {
  return stringNum === '0' || !stringNum ? false : true;
}
/**
 * boolean 转 字符串数字
 * @param {boolean} boolean
 * @return  {string} stringNum 字符串数字
 */
export function booleanToStringNum(boolean) {
  return boolean ? '1' : '0';
}
