/**
 * 移动端适配
 */
import { resize } from '@/mixins';

export default {
  mixins: [resize],
  computed: {
    contentStyle() {
      return {
        width: this.contentWidth,
        height: this.contentHeight
      };
    },
    contentWidth() {
      return this.mixin_resize_windowWidth > parseFloat(this.width)
        ? this.width
        : this.mixin_resize_windowWidth + 'px';
    },
    contentHeight() {
      return this.height;
    }
  }
};
