/**
 * 更新浏览器标题
 * @param route 路由
 */
export function updateTitle(route) {
  let names = [];
  if (route && route.meta && route.meta.title) {
    names.push(route.meta.title);
  }
  const appName = process.env.VUE_APP_NAME;
  if (appName) {
    names.push(appName);
  }
  document.title = names.join(' - ');
}
/**
 * 把菜单数据转成路由数据格式
 * @param {array} menus 菜单数组
 * @returns {array} 路由数组
 */
export function menuToRoutes(menus) {
  let permissionRoutes = [];
  const getRoutes = (menus) => {
    return menus.map((item) => {
      if (item.type === 'menu') {
        permissionRoutes.push({
          ...item,
          meta: {
            title: item.title
          },
          children: null,
          component: () =>
            import(`@/views${item.component}`).then((res) => {
              res.default.name = item.name;
              return res;
            })
        });
      } else if (item.type === 'folder') {
        permissionRoutes.push({
          ...item,
          meta: {
            title: item.title
          },
          children: null
        });
      }
      if (Array.isArray(item.children)) {
        getRoutes(item.children);
      }
    });
  };
  getRoutes(menus);
  return permissionRoutes;
}
