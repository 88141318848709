<template>
  <div class="dog-date-time-range">
    <el-date-picker
      v-model="date"
      v-bind="getBind()"
      @input="valueChange"
      v-on="filterListeners"
    >
    </el-date-picker>
  </div>
</template>

<script>
  import { timeFormat } from '@/utils/methods/common';
  import { getQuery } from '../../../src/mixins';
  import { getPickerShortcuts } from '../../../src/utils';

  const defaultAttrs = {
    rangeSeparator: '至',
    startPlaceholder: '开始日期',
    endPlaceholder: '结束日期',
    pickerOptions: {
      shortcuts: getPickerShortcuts()
    },
    clearable: false
  };
  export default {
    name: 'dogDateTimeRange',
    mixins: [getQuery],
    props: {
      value: {
        type: Array,
        default: () => []
      }
    },
    watch: {
      value() {
        this.date = this.value || [];
      }
    },
    data() {
      return {
        date: this.getArrayDefaultQuery()
      };
    },
    computed: {
      filterListeners() {
        return ['input'].reduce(
          (sum, key) => {
            delete sum[key];
            return sum;
          },
          { ...this.$listeners }
        );
      }
    },
    methods: {
      getBind() {
        defaultAttrs.pickerOptions = Object.assign(
          {},
          defaultAttrs.pickerOptions || {},
          this.$attrs.pickerOptions || {}
        );
        return {
          ...defaultAttrs,
          ...this.$attrs,
          type: 'datetimerange'
        };
      },
      valueChange(val) {
        val = val
          ? val.map((item) => timeFormat(item, 'YYYY-MM-DD hh:mm:ss'))
          : [];
        this.$emit('input', val);
      }
    }
  };
</script>
<style lang="less" scoped>
  /deep/ .el-date-editor.el-date-editor--datetimerange.el-input__inner {
    width: 100%;
    span,
    i {
      height: 32px;
      line-height: 32px;
    }
  }
</style>
