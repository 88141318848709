import baseService from '../baseService';
const request = baseService('permission');

export default {
  getMenus(params) {
    return request('/getMenus', params, 'get');
  },
  getBtns(params) {
    return request('/getBtns', params, 'get');
  }
};
