<template>
  <div class="flex-full-box">
    <div class="flex-full-box-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'flex-full-box'
  };
</script>

<style lang="less" scoped>
  .flex-full-box {
    position: relative;
    flex-grow: 1;
    &-content {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
</style>
