<template>
  <re-pagination
    :page-sizes="sizeList"
    :page-size="_pageSize"
    :current-page="pageNo"
    @size-change="sizeChange"
    @current-change="currentPage"
    :total="total"
    v-bind="getBind()"
  >
  </re-pagination>
</template>

<script>
  import rePagination from './rePagination/rePagination';

  const DEFAULT_PAGE_SIZES = [15, 30, 50, 100, 200];
  const DEFAULT_PAGEING_ATTRS = {
    layout: 'total, sizes, prev, pager, next',
    'pager-count': 5
  };
  export default {
    name: 'dog-paging',
    props: {
      total: {
        type: Number,
        default: 0
      },
      pageNo: {
        type: Number,
        default: 1
      },
      pageSize: {
        type: Number
      },
      pageSizeList: {
        type: Array,
        default: () => DEFAULT_PAGE_SIZES
      }
    },
    computed: {
      sizeList() {
        return this.pageSizeList;
      },
      _pageSize() {
        return this.pageSizeList.includes(this.pageSize)
          ? this.pageSize
          : this.pageSizeList[0];
      }
    },
    methods: {
      getBind() {
        return {
          ...DEFAULT_PAGEING_ATTRS,
          ...this.$attrs
        };
      },
      currentPage(page) {
        this.$emit('page-change', page);
      },
      sizeChange(pageSize) {
        this.$emit('size-change', pageSize);
      }
    },
    watch: {
      total(newVal) {
        let pageSize = this._pageSize;
        let maxPage = Math.ceil(newVal / pageSize);
        if (this.pageNo > maxPage) {
          this.$emit('change-page', maxPage === 0 ? 1 : maxPage, maxPage === 0);
        }
      }
    },
    components: {
      rePagination
    }
  };
</script>
<style lang="less">
  .el-pagination__sizes .el-input__suffix .el-icon-circle-close {
    display: none;
  }
  .el-pagination__sizes .el-input__suffix .el-input__validateIcon {
    display: none;
  }
</style>
