<template>
  <el-switch ref="elSwitch" :value="query" v-bind="$attrs" v-on="$listeners">
  </el-switch>
</template>

<script>
  import { getQuery } from '../../../src/mixins';

  export default {
    name: 'dog-switch',
    mixins: [getQuery],
    props: {
      value: {
        default: undefined
      }
    },
    watch: {
      value() {
        this.query = this.value;
      }
    },
    data() {
      let val = this.getDefaultQuery();
      return {
        query:
          val === undefined
            ? this.$attrs
              ? this.$attrs.activeValue || this.$attrs['active-value']
              : undefined
            : val
      };
    },
    mounted() {
      this.change(this.query);
    },
    methods: {
      change(value) {
        if (this.value !== value) {
          this.$emit('input', value);
        }
      }
    }
  };
</script>
