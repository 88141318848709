export default [
  {
    name: 'login',
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "login" */ '@/views/login/index'),
    meta: {
      title: '登录'
    }
  },
  {
    name: 'forget',
    path: '/forget',
    component: () =>
      import(/* webpackChunkName: "forget" */ '@/views/login/forget'),
    meta: {
      title: '忘记密码'
    }
  },
  {
    name: 'register',
    path: '/register',
    component: () =>
      import(/* webpackChunkName: "register" */ '@/views/login/register'),
    meta: {
      title: '注册'
    }
  }
];
