<template>
  <div class="dog-select">
    <div class="dog-select-content" :style="{ width: width + 'px' }">
      <el-select
        :value="query"
        v-bind="getBind()"
        :class="getSelectWrapClass()"
        v-on="filterListeners"
        @input="valueChange"
      >
        <el-option
          v-for="(item, index) in options_"
          :key="item.label + item.value + index"
          :label="item.label"
          :value="item.value"
          :disabled="
            disabledFn
              ? disabledFn({ item, index, parentValue: query })
              : item.disabled
          "
        >
          <component
            :is="component"
            :item="item"
            :parent-value="query"
          ></component>
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
  import { placeholder, choice, getQuery } from '../../../src/mixins';
  import { handleComponent } from '../../../src/utils';

  const DEFAULT_SELECT_ATTRS = {
    clearable: true,
    size: 'small'
  };
  export default {
    name: 'dog-select',
    props: {
      value: {
        default: undefined
      },
      label: {
        default: null
      },
      service: {
        default: null
      },
      autoFormat: {
        type: Boolean,
        default: true
      },
      selectFirst: {
        type: Boolean,
        default: false
      },
      width: {
        type: Number,
        default: null
      },
      disabledFn: {
        type: Function,
        default: null
      }
    },
    mixins: [placeholder, choice, getQuery],
    data() {
      return {
        query: this.service
          ? this.$attrs.multiple
            ? []
            : undefined
          : this.getQuery()
      };
    },
    computed: {
      component() {
        return handleComponent({
          component: this.$attrs.component,
          render: this.$attrs.render
        });
      },
      filterListeners() {
        return ['change', 'input'].reduce(
          (sum, key) => {
            delete sum[key];
            return sum;
          },
          { ...this.$listeners }
        );
      }
    },
    methods: {
      //多选时，加上特殊类名，防止内容过长时超出输入框
      getSelectWrapClass() {
        if (this.$attrs.multiple) {
          if (this.$attrs['collapse-tags']) {
            return 'select-ellipsis-multi-col';
          } else {
            return 'select-ellipsis-multi';
          }
        }
      },
      valueChange(val) {
        if (this.value !== val) {
          this.$emit('input', val);
        }
      },
      getOptions(val) {
        if (!this.service) return;
        this.optionsCache = [];
        return this.service(val).then((res) => {
          this.optionsCache = res;
          this.query = this.getQuery();
          this.valueChange(this.query);
          this.$nextTick(this.getFirstValue);
        });
      },
      getBind() {
        return {
          ...DEFAULT_SELECT_ATTRS,
          placeholder: this.getPlaceholder('select', this.label),
          ...this.$attrs
        };
      },
      getFirstValue() {
        if (this.selectFirst) {
          this.query = this.options_[0] ? this.options_[0].value : undefined;
          if (![null, undefined].includes(this.query)) {
            this.$emit('change-default', this.query);
            this.valueChange(this.query);
          }
        }
      },
      getQuery() {
        return this.$attrs.multiple
          ? this.getArrayDefaultQuery()
          : this.getDefaultQuery();
      }
    },
    mounted() {
      if (!this.service) {
        this.getFirstValue();
      }
      if (this.autoFormat) {
        this.getOptions();
      }
    },
    watch: {
      value() {
        if (this.$attrs.multiple) {
          this.query = this.value || [];
        } else {
          this.query = this.value;
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .dog-select,
  .dog-select-content {
    height: 32px;
  }
  .el-select {
    width: 100%;
  }
</style>
