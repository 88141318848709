import { Message, MessageBox } from 'element-ui';
import store from '@/store';

export default {
  baseURL: '/api',
  onResponse(res) {
    //后端返回数据时执行
    if (res.data && res.data.success) {
      res.data.msg &&
        Message.success({
          message: res.data.msg,
          showClose: true,
          offset: 80
        });
    }
    return res;
  },
  onError(msg) {
    //请求没有连接上后端时执行
    Message({ message: msg, type: 'error', showClose: true });
  },
  onFailure(res) {
    //success为false时执行
    // 登录过期
    if (res.code === 1000101) {
      MessageBox.alert('登录状态已过期，请退出重新登录！', '系统提示', {
        type: 'warning',
        showClose: false
      }).then(() => {
        store.dispatch('setToken', {});
        window.location.replace('/login'); // 这样跳转避免再次登录重复注册动态路由
      });
    } else {
      res.msg && Message({ message: res.msg, type: 'error', showClose: true });
    }
  }
};
