<template>
  <ul class="el-pager">
    <jumper in-pager></jumper>
    <span class="el-pager__connector">/</span>
    <li
      :class="{ active: currentPage === pageCount, disabled }"
      @click="onPagerClick"
      class="number"
    >
      {{ pageCount }}
    </li>
  </ul>
</template>

<script type="text/babel">
  import jumper from './jumper';
  import { Pagination } from 'element-ui';

  export default {
    extends: Pagination.components.Pager,
    inject: ['pagination'],
    components: { jumper }
  };
</script>
<style lang="less" scoped>
  span.el-pager__connector {
    display: inline-block;
    min-width: 9px;
    margin-left: 3px;
  }
</style>
