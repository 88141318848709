import Vue from '../vuePackage';
import VueRouter from 'vue-router';
import store from '@/store';
import whiteListRouter from './modules/whiteList';
import exceptionRouter from './modules/exception';
import { updateTitle, menuToRoutes } from './utils';
import NProgress from 'nprogress';
import { Message } from 'element-ui';

Vue.use(VueRouter);

// 兜底路由
const bottomRouter = {
  path: '*',
  redirect: '/exception/404'
};
const routes = [exceptionRouter, ...whiteListRouter];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  updateTitle(to);
  const isToWhiteList = whiteListRouter.find((item) => {
    return item.path === to.path;
  });
  // 未登录
  if (!store.getters.loginToken) {
    if (isToWhiteList) {
      next();
    } else {
      next({ path: '/login', query: to.path === '/' ? {} : { from: to.path } });
    }
    return;
  }
  // 未获取用户信息
  if (!store.getters.userInfo.id) {
    store.dispatch('setUserInfo');
  }
  // 未获取路由权限
  if (!store.getters.menus) {
    // 获取动态路由
    store
      .dispatch('getMenus')
      .then(({ menus, homePath }) => {
        if (menus.length === 0) {
          Message.error('该用户无任何资源权限，请联系管理员分配权限后重试！');
          router.addRoute({
            path: '/',
            redirect: '/exception/404'
          });
          next({ path: '/exception/404', replace: true });
          return;
        }
        let permissionRoutes = menuToRoutes(menus);
        router.addRoute({
          path: '/',
          component: () =>
            import(/* webpackChunkName: "layout" */ '@/components/layout'),
          redirect: homePath,
          children: permissionRoutes
        });
        router.addRoute(bottomRouter);
        const allRoutes = router.getRoutes();
        // 跳转到 from 地址
        const goToFromPath = allRoutes.find((item) => {
          return item.path === to.redirectedFrom;
        });
        if (goToFromPath) {
          next({ path: to.redirectedFrom, replace: true });
          return;
        }
        next({ ...to, replace: true });
      })
      .catch(() => {
        next();
      });
    return;
  }
  if (to.name && !store.getters.btns[to.name]) {
    store
      .dispatch('getBtns', to.name)
      .then(() => {
        next();
      })
      .catch(() => {
        next();
      });
    return;
  }
  next();
  // // 判断是否登录
  // if (store.getters.loginToken) {
  //   // 获取用户信息
  //   if (!store.getters.userInfo.id) {
  //     store.dispatch('setUserInfo');
  //   }
  //   // 判断是否已经注册动态路由
  //   if (!store.getters.menus) {
  //     // 获取动态路由
  //     store
  //       .dispatch('getMenus')
  //       .then(({ menus, homePath }) => {
  //         let permissionRoutes = menuToRoutes(menus);
  //         router.addRoute({
  //           path: '/',
  //           component: () =>
  //             import(/* webpackChunkName: "layout" */ '@/components/layout'),
  //           redirect: homePath,
  //           children: permissionRoutes
  //         });
  //         whiteListRouter.forEach((item) => {
  //           router.addRoute(item);
  //         });
  //         next({ ...to, replace: true });
  //       })
  //       .catch(() => {
  //         next();
  //       });
  //   } else {
  //     if (!store.getters.btns[to.name] && !isToWhiteList) {
  //       store
  //         .dispatch('getBtns', to.name)
  //         .then(() => {
  //           next();
  //         })
  //         .catch(() => {
  //           next();
  //         });
  //     } else {
  //       next();
  //     }
  //   }
  // } else {
  //   whiteListRouter.forEach((item) => {
  //     router.addRoute(item);
  //   });
  //   if (isToWhiteList) {
  //     next();
  //   } else {
  //     next({ path: '/login', query: to.path === '/' ? {} : { from: to.path } });
  //   }
  // }
});

router.afterEach(() => {
  setTimeout(() => {
    NProgress.done(true);
  }, 300);
});

// 禁止重复跳转导航报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
