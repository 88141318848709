<template>
  <div class="dog-item-box" :class="setVerticalAlign">
    <div
      class="dog-item-box-label"
      v-if="label"
      :title="label + labelSuffix"
      :style="{ width: labelWidth + 'px', 'text-align': labelPosition }"
    >
      <span>{{ label }}</span
      ><span>{{ labelSuffix }}</span>
    </div>
    <div class="dog-item-box-value" ref="boxValue">
      <slot>
        <component
          :is="getComponent($attrs)"
          :value="value"
          :label="label"
          v-on="$listeners"
          v-bind="attrs"
        ></component>
      </slot>
    </div>
  </div>
</template>

<script>
  import { typeMapPolicy } from '../../../src/mixins';

  export default {
    name: 'dog-item-box',
    mixins: [typeMapPolicy],
    props: {
      value: {
        default: undefined
      },
      labelSuffix: {
        type: String,
        default: ''
      },
      labelWidth: {
        type: Number,
        default: null
      },
      labelPosition: {
        type: String,
        default: null
      },
      label: {
        default: null
      },
      attrs: {}
    },
    data() {
      return {
        setVerticalAlign: null
      };
    }
  };
</script>

<style lang="less" scoped>
  .dog-item-box {
    display: flex;
    &-label {
      margin-right: 8px;
      line-height: 32px;
      flex-shrink: 0;
    }
    &-value {
      flex-grow: 1;
      line-height: 32px;
    }
  }
</style>
