<template>
  <div class="dog-dialog">
    <re-dialog
      ref="elDialog"
      :visible="dialogVisible"
      @close="defaultClose"
      @closed="closed"
      @open="open"
      v-bind="dialogBind"
      v-on="filterListeners"
    >
      <template slot="title">
        <slot name="title">
          <span class="el-dialog__title">{{ title }}</span>
        </slot>
      </template>
      <div class="dog-dialog-content" v-if="isOpen">
        <dog-scroll-box ref="dogScrollBox">
          <div class="dog-dialog-content-box" :style="contentStyle">
            <slot></slot>
          </div>
        </dog-scroll-box>
      </div>
      <div class="dog-dialog-footer" slot="footer">
        <slot name="footer" v-if="hasFooter && isOpen">
          <slot name="footer-other"></slot>
          <slot name="footer-cancel">
            <dog-button @click="closeCallback">{{
              cancelButtonText
            }}</dog-button>
          </slot>
          <slot name="footer-confirm">
            <dog-button
              type="primary"
              @click="confirm"
              :loading="
                $attrs.loading === undefined ? isloading : $attrs.loading
              "
              >{{ confirmButtonText }}</dog-button
            >
          </slot>
        </slot>
      </div>
    </re-dialog>
  </div>
</template>

<script>
  import reDialog from './reDialog';
  import dogScrollBox from '../../container-summary/dogScrollBox';
  import dogButton from '../../dogButton';
  import resizeObserver from './resizeObserver';

  const DEFAULT_DIALOG_CONFIG = {
    top: '0',
    'close-on-click-modal': false,
    'append-to-body': true
  };

  export default {
    name: 'dog-dialog',
    provide() {
      return {
        dialog: this
      };
    },
    components: {
      reDialog,
      dogScrollBox,
      dogButton
    },
    mixins: [resizeObserver],
    props: {
      // 弹窗插入的 dom 节点位置，与 element 源码逻辑一致；默认插入到路由的节点， false 时插入到 body
      appendElementId: {
        default: 'router'
      },
      title: {
        default: '标题'
      },
      hasFooter: {
        default: true
      },
      askBeforeClose: {
        type: Boolean,
        default: false
      },
      confirmButtonText: {
        type: String,
        default: '保 存'
      },
      cancelButtonText: {
        type: String,
        default: '取 消'
      },
      width: {
        type: String,
        default: '400px'
      },
      height: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        dialogVisible: false,
        isOpen: false,
        isloading: false,
        appendElement: ''
      };
    },
    computed: {
      filterListeners() {
        return ['close', 'open'].reduce(
          (sum, key) => {
            delete sum[key];
            return sum;
          },
          { ...this.$listeners }
        );
      },
      dialogBind() {
        return { ...DEFAULT_DIALOG_CONFIG, ...this.$attrs };
      }
    },
    methods: {
      confirm() {
        if (!this.$listeners['loading-confirm']) {
          this.$emit('confirm');
        } else {
          this.isloading = true;
          this.$emit('loading-confirm', () => {
            this.isloading = false;
          });
        }
      },
      open() {
        this.dialogVisible = true;
        this.isOpen = true;
        // this.$nextTick(() => {
        //   this.$refs.dogScrollbox.resize();
        // })
      },
      closed() {
        this.isOpen = false;
        this.isloading = false;
      },
      defaultClose() {
        if (this.dialogVisible) {
          this.closeCallback();
        }
      },
      closeCallback() {
        if (this.askBeforeClose) {
          this.$confirm('关闭将失去已编辑内容, 是否关闭?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              this.close();
            })
            .catch(() => {});
        } else {
          this.close();
        }
      },
      close() {
        this.dialogVisible = false;
      }
    }
  };
</script>
<style lang="less" scoped>
  /deep/ .dog-scroll-box {
    div.el-scrollbar {
      position: relative;
      height: 100%;
      .el-scrollbar__wrap {
        max-height: calc(~'100vh - 116px - 5vh');
        overflow-x: scroll;

        // @media screen and (max-height: 699px) {
        //   max-height: 450px;
        // }

        // @media screen and (min-height: 700px) and (max-height: 799px) {
        //   max-height: 550px;
        // }

        // @media screen and (min-height: 800px) and (max-height: 899px) {
        //   max-height: 650px;
        // }

        // @media screen and (min-height: 900px) {
        //   max-height: 750px;
        // }
      }
    }
  }
  /deep/ .el-dialog__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .el-dialog {
      width: auto;
      margin: 0;
      &__body {
        position: relative;
        padding: 0;
      }
    }
  }
  .dog-dialog {
    &-content {
      position: relative;
      &-box {
        position: relative;
        padding: 10px 20px;
        box-sizing: border-box;
      }
    }
  }
</style>
