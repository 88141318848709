import {
  getSessionItem,
  setSessionItem,
  getLocalStorageItem,
  setLocalStorageItem,
  deleteLocalStorageItem,
  deleteSessionItem
} from '@/utils/methods/common';
import userService from '@/api/service/user';

const user = {
  state: {
    loginToken:
      getSessionItem('loginToken') || getLocalStorageItem('loginToken') || '',
    userInfo: getSessionItem('userInfo') || {}
  },
  mutations: {
    SET_TOKEN(state, { loginToken, remember }) {
      deleteLocalStorageItem('loginToken');
      deleteSessionItem('loginToken');
      if (loginToken) {
        if (remember) {
          setLocalStorageItem('loginToken', loginToken);
        } else {
          setSessionItem('loginToken', loginToken);
        }
      }
      state.loginToken = loginToken;
    },
    SET_USER: (state, userInfo) => {
      if (userInfo) {
        state.userInfo = userInfo;
        setSessionItem('userInfo', userInfo);
      } else {
        state.userInfo = {};
        deleteSessionItem('userInfo');
      }
    }
  },
  actions: {
    setToken({ commit, dispatch }, { loginToken, remember }) {
      commit('SET_TOKEN', { loginToken, remember });
      if (!loginToken) {
        // this.dispatch('clearUserInfo');
        commit('SET_USER');
        dispatch('clearMenusAndCaches');
      }
    },
    setUserInfo({ commit }) {
      return new Promise((resolve) => {
        userService.getUserInfo().then((userInfo) => {
          commit('SET_USER', userInfo);
          resolve();
        });
      });
    }
  }
};
export default user;
