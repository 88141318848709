import { Pagination } from 'element-ui'; //2.9.2
import Pager from './pager';
import Jumper from './jumper';
const _Pagination = Object.assign({}, Pagination);
export default {
  extends: _Pagination,
  provide() {
    return {
      pagination: this
    };
  },
  components: {
    Pager,
    Jumper
  }
};
