/**
 * 移动端适配
 */
import { resize } from '@/mixins';

export default {
  mixins: [resize],
  data() {
    return {};
  },
  computed: {
    longItemWidth() {
      return this.mixin_resize_containerIsMobile
        ? this.normalItemWidth
        : this.normalItemWidth * 2;
    },
    conditionMinWidth() {
      return this.mixin_resize_containerIsMobile
        ? this.minItemWidth + 'px'
        : this.minItemWidth * 2 + 'px';
    }
  },
  watch: {
    mixin_resize_containerWidth() {
      this.computedMinItemWidth();
    }
  },
  mounted() {
    this.computedMinItemWidth();
  },
  methods: {
    computedMinItemWidth() {
      const mixin_resize_containerWidth = this.$refs.searchContent.clientWidth;
      const contentStyle = getComputedStyle(this.$refs.searchContent);
      // 最小项宽平滑过渡
      this.minItemWidth =
        (this.mixin_resize_containerIsMobile &&
          mixin_resize_containerWidth -
            parseFloat(contentStyle.paddingLeft) -
            parseFloat(contentStyle.paddingRight)) ||
        (mixin_resize_containerWidth < 640 &&
          (mixin_resize_containerWidth -
            parseFloat(contentStyle.paddingLeft) -
            parseFloat(contentStyle.paddingRight)) /
            2) ||
        (640 -
          parseFloat(contentStyle.paddingLeft) -
          parseFloat(contentStyle.paddingRight)) /
          2;
      this.resizeObserver();
    },
    countCoordinate() {
      this.mixin_resize_containerIsMobile
        ? this.countCoordinateForMobile()
        : this.countCoordinateForPc();
    },
    countCoordinateForMobile() {
      this.shellFuncForMobile((config, point) => {
        let ket = config[0];
        ket.x = point.x;
        ket.y = point.y;
        ket.isCalulate = true;
        config.splice(0, 1);
        point.x = 0;
        point.y += 1;
      });
    },
    shellFuncForMobile(countMethod) {
      this.showData.forEach((item) => {
        item.isCalulate = false;
      });
      let tempConfig = this.showData.filter((item) => !item.invisible);
      let point = { x: 0, y: 0 };
      if (!this.dropDownFlag) {
        while (tempConfig.length > 0) {
          countMethod(tempConfig, point);
        }
      } else {
        countMethod(tempConfig, point);
      }
      this.showData.forEach((item) => {
        if (item.invisible || item.isCalulate === false) {
          // item.x = -1;
          item.y = -1;
        }
      });
      this.buttonPoint.x = 0;
      this.buttonPoint.y = point.y;
    }
  }
};
