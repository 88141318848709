import copy from './copy';
import clickoutside from './clickoutside';

const directives = {
  copy,
  clickoutside
};

directives.install = function (Vue) {
  Object.keys(directives).forEach((key) => {
    Vue.directive(key, directives[key]);
  });
};

export default directives;
