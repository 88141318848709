<script>
  export default {
    inject: ['pagination'],
    props: {
      inPager: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        userInput: null
      };
    },

    watch: {
      'pagination.internalCurrentPage'() {
        this.userInput = null;
      }
    },

    methods: {
      handleKeyup({ keyCode, target }) {
        // Chrome, Safari, Firefox triggers change event on Enter
        // Hack for IE: https://github.com/ElemeFE/element/issues/11710
        // Drop this method when we no longer supports IE
        if (keyCode === 13) {
          this.handleChange(target.value);
        }
      },
      handleInput(value) {
        this.userInput = value;
      },
      handleChange(value) {
        this.pagination.internalCurrentPage = this.pagination.getValidCurrentPage(
          value
        );
        this.pagination.emitChange();
        this.userInput = null;
      }
    },

    render() {
      return (
        <span class='el-pagination__jump'>
          {!this.inPager && '前往'}
          <el-input
            class='el-pagination__editor is-in-pagination'
            min={1}
            max={this.pagination.internalPageCount}
            value={
              this.userInput !== null
                ? this.userInput
                : this.pagination.internalCurrentPage
            }
            type='number'
            disabled={this.pagination.disabled}
            nativeOnKeyup={this.handleKeyup}
            onInput={this.handleInput}
            onChange={this.handleChange}
          />
          {!this.inPager && '页'}
        </span>
      );
    }
  };
</script>
<style lang="less" scoped>
  .el-pagination__jump {
    margin-left: 0;
  }
</style>
