import permissionService from '@/api/service/permission';
import {
  getSessionItem,
  setSessionItem,
  deleteSessionItem
} from '@/utils/methods/common';

const menu = {
  state: {
    menus: undefined,
    btns: {},
    visitedMenus: getSessionItem('visitedMenus') || [],
    activeMenu: getSessionItem('activeMenu') || {},
    cachedNames: [],
    homePath: ''
  },
  mutations: {
    SET_MENUS: (state, menus) => {
      if (Array.isArray(menus)) {
        state.menus = menus;
      }
    },
    SET_BTNS: (state, { btns, name }) => {
      if (Array.isArray(btns)) {
        state.btns[name] = btns;
      }
    },
    SET_HOME_PATH: (state, homePath) => {
      state.homePath = homePath;
    },
    CLEAR_MENUS: (state) => {
      state.menus = undefined;
    },
    CLEAR_BTNS: (state) => {
      state.btns = {};
    },
    SET_VISITED_MENU: (state, visitedMenus) => {
      if (visitedMenus) {
        state.visitedMenus = visitedMenus;
        setSessionItem('visitedMenus', visitedMenus);
      } else {
        state.visitedMenus = [];
        deleteSessionItem('visitedMenus');
      }
    },
    SET_ACTIVE_MENU: (state, activeMenu) => {
      if (activeMenu) {
        state.activeMenu = activeMenu;
        setSessionItem('activeMenu', activeMenu);
      } else {
        state.activeMenu = {};
        deleteSessionItem('activeMenu');
      }
    },
    SET_CACHED_NAMES: (state, cachedNames) => {
      if (Array.isArray(cachedNames)) {
        state.cachedNames = cachedNames;
      }
    }
  },
  actions: {
    getMenus({ commit }) {
      return new Promise((resolve) => {
        permissionService.getMenus().then(({ menus, homePath }) => {
          commit('SET_MENUS', menus);
          commit('SET_HOME_PATH', homePath);
          resolve({ menus, homePath });
        });
      });
    },
    getBtns({ commit }, name) {
      return new Promise((resolve) => {
        permissionService.getBtns({ name }).then((btns) => {
          commit('SET_BTNS', { btns, name });
          resolve(btns);
        });
      });
    },
    setVisitedMenus({ commit }, visitedMenus) {
      commit('SET_VISITED_MENU', visitedMenus);
    },
    setActiveMenu({ commit }, activeMenu) {
      commit('SET_ACTIVE_MENU', activeMenu);
    },
    addCacheName({ commit, state }, cacheName) {
      const isExist = state.cachedNames.find((item) => {
        return item === cacheName;
      });
      if (!isExist) {
        const cachedNames = [...state.cachedNames, cacheName];
        commit('SET_CACHED_NAMES', cachedNames);
      }
    },
    removeCachedNames({ commit, state }, removedNames) {
      const cachedNames = state.cachedNames.filter((item) => {
        return !removedNames.includes(item);
      });
      commit('SET_CACHED_NAMES', cachedNames);
    },
    clearMenusAndCaches({ commit }) {
      commit('CLEAR_MENUS');
      commit('SET_HOME_PATH');
      commit('CLEAR_BTNS');
      commit('SET_VISITED_MENU');
      commit('SET_ACTIVE_MENU');
      commit('SET_CACHED_NAMES', []);
    }
  }
};
export default menu;
