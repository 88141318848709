export default {
  props: {
    lazyLoad: {
      type: Boolean,
      default: false
    },
    service: {
      type: Function,
      default: () => Promise.resolve({ list: [] })
    },
    clearDataBefore: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      query: null,
      isLoadingData: false,
      loadingApi: null
    };
  },
  destroyed() {
    this.abortRequest();
  },
  mounted() {
    if (this.lazyLoad) {
      this.$nextTick(() => {
        let tableBodyWrapper = this.$el.querySelector(
          '.el-table__body-wrapper'
        );
        this.mixin_listenerPolicy_addListener(
          tableBodyWrapper,
          'scroll',
          () => {
            if (
              this.realTotal > this.showData.length &&
              tableBodyWrapper.scrollTop !== 0 &&
              tableBodyWrapper.scrollTop + tableBodyWrapper.clientHeight >=
                tableBodyWrapper.scrollHeight - 42
            ) {
              this.load();
            }
          }
        );
      });
    }
  },
  watch: {
    isLoadingData() {
      if (!this.isLoadingData) {
        this.$emit('loaded');
      }
    }
  },
  methods: {
    abortRequest() {
      this.loadingApi &&
        this.loadingApi.source &&
        this.loadingApi.source.cancel('table request abort');
    },
    load() {
      if (this.isLoadingData) return;
      this.query.pageNo++;
      if (this.staticPaging) {
        this.staticData = this.staticData.concat(this.staticSearch());
        return Promise.resolve();
      } else {
        this.isLoadingData = true;
        this.loadingApi = this.service(this.query, 'lazyLoad')
          .then((res) => {
            this.tableData = this.tableData.concat(res.list);
            this.total = res.total;
          })
          .finally(() => {
            this.isLoadingData = false;
          });
        return this.loadingApi;
      }
    },
    loopGetData() {
      if (this.lazyLoad) {
        let tableBodyWrapper = this.$el.querySelector(
          '.el-table__body-wrapper'
        );
        let tableBody = this.$el.querySelector('.el-table__body');
        this.$nextTick(() => {
          if (
            this.realTotal > this.showData.length &&
            tableBodyWrapper.clientHeight >= tableBody.clientHeight
          ) {
            return this.load().then(this.loopGetData);
          }
        });
      }
      return Promise.resolve();
    },
    staticSearch() {
      return this.staticDataCache.slice(
        (this.query.pageNo - 1) * this.query.pageSize,
        this.query.pageNo * this.query.pageSize
      );
    },
    getTableData_service() {
      if (this.clearDataBefore) {
        this.tableData = [];
      }
      this.abortRequest();
      this.isLoadingData = true;
      this.loadingApi = this.service(this.query)
        .then((res = { list: [], total: 0 }) => {
          this.tableData = res.list;
          this.total = res.total;
        })
        .then(() => {
          this.isLoadingData = false;
          return this.loopGetData();
        })
        .catch((err) => {
          if (err.constructor.name !== 'Cancel') {
            this.isLoadingData = false;
          }
        });
      return this.loadingApi;
    },
    getTableData_static(type) {
      if (type === 'search') {
        if (this.clearDataBefore) {
          this.staticDataCache = [];
          this.staticData = [];
        }
        this.abortRequest();
        this.isLoadingData = true;
        this.loadingApi = this.service(this.query)
          .then((res) => {
            this.staticDataCache = res.list;
            this.staticData = this.staticSearch();
          })
          .then(() => {
            this.isLoadingData = false;
            return this.loopGetData();
          })
          .catch((err) => {
            if (err.constructor.name !== 'Cancel') {
              this.isLoadingData = false;
            }
          });
        return this.loadingApi;
      } else {
        this.staticData = this.staticSearch();
        return Promise.resolve();
      }
    },
    getTableData(query, type) {
      this.query = { ...query, ...this.orderQuery };
      this.query.pageSize = this.query.pageSize || 10;
      this.query.pageNo = this.query.pageNo || 1;
      this.startPage = this.query.pageNo;
      reset_animation.call(this);
      (this.staticPaging
        ? this.getTableData_static(type)
        : this.getTableData_service()
      ).then(() => {
        this.$emit('data-loaded');
      });
    }
  }
};
function reset_animation() {
  let el = this.$refs.progress.querySelector('.tab');
  el.style.animation = 'none';
  el.offsetHeight;
  el.style.animation = null;
}
