import { addClass, removeClass } from '@/utils/methods/common';

class Vertical {
  beforeEnter(el) {
    addClass(el, 'collapse-transition');
    if (!el.dataset) el.dataset = {};
    el.dataset.oldPaddingTop = el.style.paddingTop;
    el.dataset.oldPaddingBottom = el.style.paddingBottom;
    el.style.height = '0';
    el.style.paddingTop = 0;
    el.style.paddingBottom = 0;
  }
  enter(el) {
    el.dataset.oldOverflow = el.style.overflow;
    if (el.scrollHeight !== 0) {
      el.style.height = el.scrollHeight + 'px';
      el.style.paddingTop = el.dataset.oldPaddingTop;
      el.style.paddingBottom = el.dataset.oldPaddingBottom;
    } else {
      el.style.height = '';
      el.style.paddingTop = el.dataset.oldPaddingTop;
      el.style.paddingBottom = el.dataset.oldPaddingBottom;
    }
    el.style.overflow = 'hidden';
  }
  afterEnter(el) {
    removeClass(el, 'collapse-transition');
    el.style.height = '';
    el.style.overflow = el.dataset.oldOverflow;
  }
  beforeLeave(el) {
    if (!el.dataset) el.dataset = {};
    el.dataset.oldPaddingTop = el.style.paddingTop;
    el.dataset.oldPaddingBottom = el.style.paddingBottom;
    el.dataset.oldOverflow = el.style.overflow;
    el.style.height = el.scrollHeight + 'px';
    el.style.overflow = 'hidden';
  }
  leave(el) {
    if (el.scrollHeight !== 0) {
      addClass(el, 'collapse-transition');
      el.style.height = 0;
      el.style.paddingTop = 0;
      el.style.paddingBottom = 0;
    }
  }
  afterLeave(el) {
    removeClass(el, 'collapse-transition');
    el.style.height = '';
    el.style.overflow = el.dataset.oldOverflow;
    el.style.paddingTop = el.dataset.oldPaddingTop;
    el.style.paddingBottom = el.dataset.oldPaddingBottom;
  }
}

class Horizontal {
  beforeEnter(el) {
    addClass(el, 'collapse-transition');
    if (!el.dataset) el.dataset = {};
    el.dataset.oldPaddingLeft = el.style.paddingLeft;
    el.dataset.oldPaddingRight = el.style.paddingRight;
    el.style.width = '0';
    el.style.paddingLeft = 0;
    el.style.paddingRight = 0;
  }
  enter(el) {
    el.dataset.oldOverflow = el.style.overflow;
    if (el.scrollWidth !== 0) {
      el.style.width = el.scrollWidth + 'px';
      el.style.paddingLeft = el.dataset.oldPaddingLeft;
      el.style.paddingRight = el.dataset.oldPaddingRight;
    } else {
      el.style.width = '';
      el.style.paddingLeft = el.dataset.oldPaddingLeft;
      el.style.paddingRight = el.dataset.oldPaddingRight;
    }
    el.style.overflow = 'hidden';
  }
  afterEnter(el) {
    removeClass(el, 'collapse-transition');
    el.style.width = '';
    el.style.overflow = el.dataset.oldOverflow;
  }
  beforeLeave(el) {
    if (!el.dataset) el.dataset = {};
    el.dataset.oldPaddingLeft = el.style.paddingLeft;
    el.dataset.oldPaddingRight = el.style.paddingRight;
    el.dataset.oldOverflow = el.style.overflow;
    el.style.width = el.scrollWidth + 'px';
    el.style.overflow = 'hidden';
  }
  leave(el) {
    if (el.scrollWidth !== 0) {
      addClass(el, 'collapse-transition');
      el.style.width = 0;
      el.style.paddingLeft = 0;
      el.style.paddingRight = 0;
    }
  }
  afterLeave(el) {
    removeClass(el, 'collapse-transition');
    el.style.width = '';
    el.style.overflow = el.dataset.oldOverflow;
    el.style.paddingLeft = el.dataset.oldPaddingLeft;
    el.style.paddingRight = el.dataset.oldPaddingRight;
  }
}

export default {
  name: 'dogCollapseTransition',
  functional: true,
  props: {
    direction: {
      type: String,
      default: 'vertical'
    }
  },
  render(h, { children, props }) {
    const data = {};
    if (props.direction === 'vertical') data.on = new Vertical();
    if (props.direction === 'horizontal') data.on = new Horizontal();
    return h('transition', data, children);
  }
};
