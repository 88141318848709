<template>
  <div
    class="dog-search"
    @click.stop
    :style="{
      height: expandAll
        ? (buttonPoint.y + 1) * normalItemHeight + 20 + 'px'
        : mixin_resize_containerIsMobile
        ? '108px'
        : '64px'
    }"
  >
    <div
      class="dog-search-mask"
      v-show="!dropDownFlag && !expandAll"
      @click="clickOutSide"
    ></div>
    <div
      class="dog-search-content"
      :class="{ relativePosition: expandAll }"
      ref="searchContent"
    >
      <div
        class="dog-search-content-condition clearfix"
        ref="searchcondition"
        :style="{
          minWidth: conditionMinWidth
        }"
      >
        <div
          class="content"
          :style="{
            width: columnsNumber * normalItemWidth + 'px',
            height: (buttonPoint.y + 1) * normalItemHeight + 'px'
          }"
        >
          <div
            class="dog-search-content-condition-item"
            :style="{
              width: (item.isLong ? longItemWidth : normalItemWidth) + 'px',
              height: normalItemHeight + 'px',
              left: item.x * normalItemWidth + 'px',
              top: item.y * normalItemHeight + 'px',
              display: item.y === -1 ? 'none' : 'block'
            }"
            v-for="(item, index) in showData"
            :key="index"
          >
            <dog-item-box v-bind="itemBoxBind(item)">
              <component
                @change-default="item.attrs.default = $event"
                :ref="getKey(item)"
                :is="getComponent(item)"
                :label="item.label"
                :value="getValueByKeys(item)"
                @input="setVauleByKeys(item, $event)"
                @keydown.enter.native="
                  quickSearchControls.includes(item.type) && search()
                "
                v-on="listenersMap[getKey(item)]"
                v-bind="item.attrs"
              >
              </component>
            </dog-item-box>
          </div>
          <div
            class="dog-search-content-condition-button"
            v-if="mixin_resize_containerIsMobile"
            :style="{
              width: normalItemWidth + 'px',
              height: normalItemHeight + 'px',
              left: buttonPoint.x * normalItemWidth + 'px',
              top: buttonPoint.y * normalItemHeight + 'px'
            }"
          >
            <span
              class="switch"
              v-if="configLength >= columnsNumber && !expandAll"
            >
              <span @click="dropDownFlag = false" v-if="dropDownFlag"
                ><i class="el-icon-arrow-down"></i
              ></span>
              <span @click="dropDownFlag = true" v-else
                ><i class="el-icon-arrow-up"></i
              ></span>
            </span>
            <el-button
              size="small"
              @click="restQuery"
              v-show="showResetButton"
              class="smallBtn"
              ><i class="el-icon-refresh"></i
            ></el-button>
            <el-button
              type="primary"
              plain
              size="small"
              @click="search"
              class="smallBtn"
              ><i class="el-icon-search"></i
            ></el-button>
          </div>
          <div
            class="dog-search-content-condition-button"
            v-else
            :style="{
              width: normalItemWidth + 'px',
              height: normalItemHeight + 'px',
              left: buttonPoint.x * normalItemWidth + 'px',
              top: buttonPoint.y * normalItemHeight + 'px'
            }"
          >
            <span
              class="switch"
              v-if="configLength >= columnsNumber && !expandAll"
            >
              <span @click="dropDownFlag = false" v-if="dropDownFlag"
                >展开<i class="el-icon-arrow-down"></i
              ></span>
              <span @click="dropDownFlag = true" v-else
                >收起<i class="el-icon-arrow-up"></i
              ></span>
            </span>
            <el-button size="medium" @click="restQuery" v-show="showResetButton"
              >重置</el-button
            >
            <el-button type="primary" plain size="medium" @click="search"
              >搜索</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { typeMapPolicy, listenersMap } from '../../src/mixins';
  import layout from './layout';
  import { deepClone, checkType } from '@/utils/methods/common';
  import resizeObserver from './resizeObserver';

  export default {
    name: 'dog-search',
    props: {
      quickSearchControls: {
        type: Array,
        default: () => ['input', 'el-input']
      },
      query: {
        type: Object,
        default: () => ({})
      },
      config: {
        type: Array,
        default: () => []
      },
      showResetButton: {
        type: Boolean,
        default: true
      },
      // 平铺展开全部
      expandAll: {
        type: Boolean,
        default: false
      }
    },
    mixins: [
      typeMapPolicy,
      listenersMap({ configs: 'config', key: 'key' }),
      layout,
      resizeObserver
    ],
    data() {
      return {
        hidden: false,
        cache: {}
      };
    },
    methods: {
      setVauleByKeys(item, val) {
        if (Array.isArray(item.key)) {
          item.key.forEach((k, index) => {
            this.$set(
              this.query,
              k,
              Array.isArray(val) ? val[index] : undefined
            );
          });
        } else {
          this.$set(this.query, item.key, val);
        }
        this.$emit('change', this.query, item);
      },
      getValueByKeys(item) {
        if (Array.isArray(item.key)) {
          return item.key.map((k) => this.query[k]);
        } else {
          return this.query[item.key];
        }
      },
      getBaseQuery() {
        this.config.forEach((item) => {
          let { attrs = {} } = item;
          let _default = attrs.default;
          if (checkType(_default, ['Object', 'Array'])) {
            _default = deepClone(_default);
          }
          this.setVauleByKeys(item, _default);
        });
      },
      search() {
        let tempQuery = deepClone(this.query);
        this.$emit('search', tempQuery);
        !this.expandAll && (this.dropDownFlag = true);
        this.cache = tempQuery;
      },
      restQuery() {
        if (this.$listeners['before-reset']) {
          this.$emit('before-reset');
          this.$nextTick(() => {
            this.getBaseQuery();
          });
        } else {
          this.getBaseQuery();
        }
      },
      itemBoxBind(item) {
        return {
          ...this.$attrs,
          ...item,
          key: item.key.toString()
        };
      }
    }
  };
</script>
<style lang="less" scoped>
  .dog-search {
    width: 100%;
    height: 64px;
    font-size: 14px;
    flex: 0 0 auto;
    box-sizing: border-box;
    * {
      box-sizing: border-box;
    }
    &-content {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      padding: 10px ~'min(24px, 2vw)';
      overflow: auto;
      background-color: #fff;
      transition: all 0.5s;
      &.relativePosition {
        position: relative;
      }
      &-condition {
        width: 100%;
        background-color: #fff;
        .content {
          position: relative;
        }
        &-item {
          position: absolute;
          display: inline-block;
          padding-right: 8px;
        }
        &-button {
          position: absolute;
          height: 44px;
          padding-right: 8px;
          line-height: 44px;
          text-align: right;
          box-sizing: border-box;
          .el-button {
            width: 72px;
            margin-left: 8px;
          }
          .smallBtn {
            width: 36px;
            padding: 9px;
          }
          .switch {
            span {
              color: #3654ea;
              cursor: pointer;
              i {
                margin-left: 4px;
              }
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    &-mask {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.45);
    }
  }
  .dog-item-box {
    margin: 6px 0;
  }
</style>
