<template>
  <div class="dog-scroll-box">
    <el-scrollbar ref="scrollbar" v-bind="$attrs">
      <slot></slot>
    </el-scrollbar>
  </div>
</template>
<script>
  // 无论容器内部是否需要自定义滚动条，自定义滚动条的相关元素都会生成，所以尽量避免容器嵌套时，两容器bottom和right两边重合的情况

  export default {
    name: 'dog-scroll-box',
    data: function () {
      return {
        scrollbar: undefined
      };
    },
    props: {
      isOnResize: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      sizeWidth() {
        return this.scrollbar ? this.scrollbar.sizeWidth : '';
      }
    },
    watch: {
      sizeWidth(newValue) {
        this.$el.querySelector(
          '.el-scrollbar__bar.is-horizontal'
        ).style.display = newValue ? 'block' : 'none';
      }
    },
    // render(h) {
    //   return h('el-scrollbar', {
    //     ref: 'scrollbar',
    //     props: {
    //       ...this.$attrs
    //     }
    //   }, this.$slots.default);
    // },
    mounted() {
      this.scrollbar = this.$refs.scrollbar;
      this.isOnResize &&
        this.mixin_listenerPolicy_addListener(
          this.$el.querySelector('.el-scrollbar__view').childNodes[0],
          'resizeObserver',
          this.resize
        );
      this.isOnResize &&
        this.mixin_listenerPolicy_addListener(
          this.$el,
          'resizeObserver',
          this.resize
        );
      this.mixin_listenerPolicy_addListener(
        this.$refs.scrollbar.$el.querySelector('.el-scrollbar__wrap'),
        'scroll',
        (event) => {
          let scrollTop = event.target.scrollTop;
          let clientHeight = event.target.clientHeight;
          let scrollHeight = event.target.scrollHeight;
          if (
            scrollHeight > clientHeight &&
            scrollTop + clientHeight === scrollHeight
          ) {
            this.$emit('at-bottom', event);
          }
        }
      );
    },
    // activated() {
    //   this.mixin_listenerPolicy_addListener(window, 'resize', this.resize);
    //   // this.resizeListener();
    // },
    // deactivated() {
    //   if (this.escapeListenerResize) {
    //     this.escapeListenerResize();
    //     this.escapeListenerResize = undefined;
    //   }
    // },
    // beforeDestroy() {
    //   this.escapeListenerResize && this.escapeListenerResize();
    // },
    methods: {
      moveToTarget(el) {
        el.scrollIntoView();
      },
      resize() {
        this.scrollbar && this.scrollbar.update();
      }
    }
  };
</script>
<style lang="less" scoped>
  /deep/ .el-dialog__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .el-dialog {
      width: auto;
      margin: 0;
      &__body {
        position: relative;
        padding: 0;
      }
    }
  }
  .dog-dialog {
    &-content {
      position: relative;
      /deep/ & > .dog-scroll-box {
        & > .el-scrollbar {
          position: relative;
          height: 100%;
          & > .el-scrollbar__wrap {
            max-height: calc(~'100vh - 116px - 5vh');
            overflow-x: scroll;

            // @media screen and (max-height: 699px) {
            //   max-height: 450px;
            // }

            // @media screen and (min-height: 700px) and (max-height: 799px) {
            //   max-height: 550px;
            // }

            // @media screen and (min-height: 800px) and (max-height: 899px) {
            //   max-height: 650px;
            // }

            // @media screen and (min-height: 900px) {
            //   max-height: 750px;
            // }
          }
        }
      }
      &-box {
        position: relative;
        padding: 10px 20px;
        box-sizing: border-box;
      }
    }
  }
</style>
