<template>
  <div class="dog-date-time">
    <el-date-picker
      v-model="date"
      v-bind="getBind()"
      @input="valueChange"
      v-on="filterListeners"
    >
    </el-date-picker>
  </div>
</template>

<script>
  import { timeFormat } from '@/utils/methods/common';
  import { placeholder, getQuery } from '../../../src/mixins';
  import { getDatePickerShortcuts } from '../../../src/utils';

  const defaultAttrs = {
    placeholder: '选择日期时间',
    pickerOptions: {
      shortcuts: getDatePickerShortcuts('datetime')
    },
    align: 'right',
    clearable: false
  };
  export default {
    name: 'dogDateTime',
    mixins: [placeholder, getQuery],
    props: {
      label: {
        type: String,
        default: '日期'
      },
      value: {
        type: String,
        default: undefined
      }
    },
    watch: {
      value() {
        this.date = this.value || '';
      }
    },
    data() {
      return {
        date: this.getDefaultQuery()
      };
    },
    computed: {
      filterListeners() {
        return ['input'].reduce(
          (sum, key) => {
            delete sum[key];
            return sum;
          },
          { ...this.$listeners }
        );
      }
    },
    methods: {
      getBind() {
        defaultAttrs.pickerOptions = Object.assign(
          {},
          defaultAttrs.pickerOptions || {},
          this.$attrs.pickerOptions || {}
        );
        return {
          ...defaultAttrs,
          placeholder: this.getPlaceholder('select', this.label),
          ...this.$attrs,
          type: 'datetime'
        };
      },
      valueChange(val) {
        val = val ? timeFormat(val, 'YYYY-MM-DD hh:mm:ss') : '';
        this.$emit('input', val);
      }
    }
  };
</script>
<style lang="less" scoped>
  /deep/ .el-date-editor.el-date-editor.el-input {
    width: 100%;
    span,
    i {
      height: 32px;
      line-height: 32px;
    }
  }
</style>
