import {
  setLocalStorageItem,
  getLocalStorageItem
} from '@/utils/methods/common';

export const setTableColumnsCacheById = function (id, columns) {
  let tableColumnsCache = getLocalStorageItem('tableColumnsCache') || {};
  tableColumnsCache[id] = columns;
  setLocalStorageItem('tableColumnsCache', tableColumnsCache);
};
export const getTableColumnsCacheById = function (id) {
  let tableColumnsCache = getLocalStorageItem('tableColumnsCache') || {};
  return tableColumnsCache[id] || [];
};
export const deleteTableColumnsCacheById = function (id) {
  let tableColumnsCache = getLocalStorageItem('tableColumnsCache') || {};
  delete tableColumnsCache[id];
  setLocalStorageItem('tableColumnsCache', tableColumnsCache);
};
