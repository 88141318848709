const regexp = {
  //整数+小数
  figure: /^(\d+(\.\d*)?)?$/,
  // 固定电话
  phone: /^(\d{3}-\d{8,11}|\d{4}-\d{7,10})$/,
  // 手机号
  telephone: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
  // 短号
  shortPhone: /^\d{6}$/,
  // 身份证号
  idCard: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
  // 邮箱
  email: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
  // 正整数
  posInt: /^[1-9]\d*$/,
  // 数字或者为空
  intWithBlank: /^(\d*){0,1}$/,
  // 非法字符
  illegal: /[\s]/
};
export default regexp;
