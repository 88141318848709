<template>
  <div class="dog-form">
    <el-form
      ref="ruleForm"
      :model="formData"
      v-bind="bindForm()"
      @submit.native.prevent
    >
      <dog-grid-box :data="formOptions" v-bind="$attrs">
        <template slot-scope="{ item }">
          <el-form-item
            v-bind="{
              ...item.formItem,
              prop: Array.isArray(item.formItem.prop)
                ? item.formItem.prop[0]
                : item.formItem.prop
            }"
            :class="getItemClass(item)"
            :key="
              Array.isArray(item.formItem.prop)
                ? item.formItem.prop[0]
                : item.formItem.prop
            "
          >
            <template v-if="item.type">
              <component
                v-if="!(item.computed || item.formatter)"
                :is="getComponent(item)"
                :ref="getKey(item)"
                :value="getValueByPath_(formData, item.formItem.prop)"
                @input="setValueByPath(formData, item.formItem.prop, $event)"
                v-bind="getBind(item)"
                v-on="listenersMap[getKey(item)]"
              >
              </component>
              <component
                v-else
                :is="getComponent(item)"
                :ref="getKey(item)"
                :value="valueExtend(item)"
                v-bind="getBind(item)"
                v-on="listenersMap[getKey(item)]"
              >
              </component>
            </template>
            <span class="dog-form_text" v-else>{{ valueExtend(item) }}</span>
          </el-form-item>
        </template>
      </dog-grid-box>
    </el-form>
  </div>
</template>

<script>
  import { typeMapPolicy, listenersMap } from '../../../src/mixins';
  import dogGridBox from '../../container-summary/dogGridBox';
  import { checkType, getValueByPath, deepClone } from '@/utils/methods/common';

  const formAttrs = {
    'label-position': 'right',
    'label-width': '96px',
    'label-suffix': ''
  };
  function judgeEmpty(val) {
    return (
      ['', undefined, null].includes(val) ||
      (Array.isArray(val) &&
        val.every((v) => ['', undefined, null].includes(v))) ||
      (checkType(val, 'Object') &&
        Object.keys(val).every((key) =>
          ['', undefined, null].includes(val[key])
        ))
    );
  }
  export default {
    name: 'dog-form',
    mixins: [typeMapPolicy, listenersMap()],
    props: {
      //表单数据
      formData: {
        type: Object,
        default: () => ({})
      },
      //表单参数
      formOptions: {
        type: Array,
        default: () => [
          {
            type: '', //必填，当前项的类型：input(输入框)、select(下拉框)、date(日期控件)、custom(自定义组件)
            // hasWordsCount: false, //非必填，是否包含字数统计，仅在type为input的时候生效
            formItem: {
              //必填，form-item元素的绑定值
              prop: '', //必填，当前项的prop和model名
              label: '', //必填，当前项的label
              rules: [] //必填，当前项的校验规则
            },
            attrs: {}, //非必填，组件的绑定值
            // subAtrrs: {}, //非必填，组件子元素的绑定值
            listeners: {}, //非必填，组件的绑定事件
            // subListeners: {}, //非必填，组件子元素的绑定事件
            component: {}, //非必填，自定义组件
            render: () => {} //非必填，自定义组件
            // keyMap: {}, //非必填，枚举关键字映射表
            // subList: []//非必填，组件子元素的枚举
          }
        ]
      },
      //是否为编辑（编辑下初始化即校验表单）
      isEdit: {
        type: Boolean,
        default: false
      },
      autoComplete: {
        type: Boolean,
        default: true
      }
    },
    created() {
      this.init();
    },
    watch: {
      formData() {
        this.init();
      }
    },
    mounted() {
      this.$nextTick(() => {
        //如果处于编辑状态，则初始化时自动校验表单，反之不校验
        if (this.isEdit) {
          this.validate();
        }
      });
    },
    methods: {
      loopSetvalue(obj, path, value) {
        if (!obj || !path) return;
        let paths = path.split('.');
        paths.reduce((sum, p, index) => {
          if (index === paths.length - 1) {
            this.$set(sum, p, value);
          } else {
            if (typeof sum[p] !== 'object') {
              this.$set(sum, p, {});
            }
            return sum[p];
          }
        }, obj);
      },
      setValueByPath(obj, path, value) {
        if (Array.isArray(path)) {
          path.forEach((p, index) =>
            this.loopSetvalue(
              obj,
              p,
              Array.isArray(value) ? value[index] : undefined
            )
          );
        } else {
          this.loopSetvalue(obj, path, value);
        }
      },
      getValueByPath_(obj, path) {
        if (Array.isArray(path)) {
          return path.map((p) => getValueByPath(obj, p));
        } else {
          return getValueByPath(obj, path);
        }
      },
      init() {
        this.formOptions.forEach((item) => {
          if (
            (Array.isArray(item.formItem.prop) &&
              this.getValueByPath_(this.formData, item.formItem.prop).every(
                (v) => !v
              )) ||
            (!Array.isArray(item.formItem.prop) &&
              [undefined, null].includes(
                this.getValueByPath_(this.formData, item.formItem.prop)
              ))
          ) {
            let { attrs = {} } = item;
            let _default = attrs.default;
            if (checkType(_default, ['Object', 'Array'])) {
              _default = deepClone(_default);
            }
            this.setValueByPath(this.formData, item.formItem.prop, _default);
          }
        });
      },
      getItemClass(item) {
        let arr = [];
        let prop = item.formItem.prop;
        if (judgeEmpty(this.formData[prop])) {
          arr.push('is-empty');
        }
        return arr;
      },
      //通过方法设定该模块的值
      valueExtend(item) {
        let { computed, formatter } = item;
        if (computed || formatter) {
          let isComputed = Boolean(computed);
          let fn = isComputed ? computed : formatter;
          let val = fn.bind(this)(this.getBind(item));
          if (isComputed) {
            this.$set(this.formData, this.getKey(item), val);
          }
          return val;
        } else {
          return this.formData[this.getKey(item)];
        }
      },
      /**
       * 删除el-form绑定属性
       */
      bindForm() {
        return Object.assign({}, formAttrs, this.$attrs);
      },
      /**
       * 绑定组件的配置项
       * @param bind 传入的配置项
       * @param type 组件的类型
       */
      getBind(item) {
        return Object.assign(
          {
            label:
              (this.isExistType(item.type) && item.formItem.label) || undefined,
            item,
            cur: this.formData[this.getKey(item)],
            autocomplete: this.autoComplete
              ? undefined
              : 'new-' + item.formItem.prop
          },
          item['attrs']
        );
      },
      /**
       * 手动表单校验
       * @param fn 回调函数
       */
      validate(successFn, errorFn) {
        return new Promise((resolve, reject) => {
          this.$refs.ruleForm.validate((valid, obj) => {
            if (valid) {
              typeof successFn === 'function' && successFn(obj);
              resolve(obj);
            } else {
              typeof errorFn === 'function' && errorFn(obj);
              reject(obj);
            }
          });
        });
      },
      /**
       * 专供单元测试的表单校验
       * @return validateError 由未通过校验的字段名形成的数组
       */
      validateForTest() {
        let validateError = [];
        this.$refs.ruleForm.validate((valid, error) => {
          for (let item of Object.keys(error)) {
            validateError.push(error[item][0].message);
          }
        });
        return validateError;
      },
      /**
       * 手动重置表单
       */
      resetFields() {
        this.$refs.ruleForm !== undefined && this.$refs.ruleForm.resetFields();
      },
      /**
       * 对部分表单字段进行校验
       * @param prop 校验的字段
       * @param callback 回调函数
       */
      validateField(prop, callback) {
        this.$refs.ruleForm.validateField(prop, callback);
      },
      /**
       * 移除表单项的校验结果
       * @param props 待移除的字段数组
       */
      clearValidate(props) {
        this.$refs.ruleForm.clearValidate(props);
      },
      /**
       * 修改表单配置项属性
       * @param prop 配置项的props属性，根据此属性查询当前表单项
       * @param attr 配置项的第一层属性，如bind,subBind
       * @param subAttrOrValue 配置项的第二层属性（如bind下的placeholder）或将要覆盖的值
       * @param value 将要覆盖的值
       */
      modifyFormOption(prop, attr, subAttrOrValue, value) {
        let optionIndex = (() => {
          for (let index in Object.keys(this.formOptions)) {
            if (this.formOptions[index].formItem.prop === prop) {
              return index;
            }
          }
        })();
        if (optionIndex !== undefined) {
          if (arguments.length === 3) {
            this.$set(this.formOptions[optionIndex], attr, subAttrOrValue);
            // this.formOptions[optionIndex][attr] = value;
          } else {
            if (!this.formOptions[optionIndex][attr]) {
              this.$set(this.formOptions[optionIndex], attr, {});
            }
            this.$set(
              this.formOptions[optionIndex][attr],
              subAttrOrValue,
              value
            );
            // this.formOptions[optionIndex][attr][subAttr] = value;
          }
        }
      }
    },
    components: {
      dogGridBox
    }
  };
</script>
<style lang="less">
  .el-form-item {
    margin-bottom: 18px!important;
    &__error {
      padding-top: 2px;
    }
    &__label,
    &__content {
      line-height: 32px;
    }
  }
</style>
