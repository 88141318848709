const TYPES = ['selection', 'index'];
const TYPE_COLUMN_OPTION = {
  selection: {
    fixed: 'left',
    width: 35
  },
  index: {
    fixed: 'left',
    width: 48,
    label: '序号'
  }
};
export default {
  props: {
    columnType: null
  },
  computed: {
    typesColumns() {
      const { columnType: type } = this;
      let typeColumns = [];
      if (Array.isArray(type)) {
        typeColumns = type.map((it) => {
          return typeof it === 'object' ? it : { type: it };
        });
      } else {
        typeColumns = [{ type }];
      }
      return typeColumns.filter((it) => {
        let temp = TYPES.find((t) => t === it.type);
        if (temp) {
          it.col = Object.assign({}, TYPE_COLUMN_OPTION[it.type], it.props);
        }
        return temp;
      });
    }
  }
};
