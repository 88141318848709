export default {
  props: {
    backOrder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      orderQuery: {},
      startPage: 1
    };
  },
  methods: {
    sortChange({ prop, order }) {
      this.orderQuery.orderField = prop;
      switch (order) {
        case 'ascending':
          this.orderQuery.orderDirection = 'ASC';
          break;
        case 'descending':
          this.orderQuery.orderDirection = 'DESC';
          break;
        default:
          this.orderQuery.orderDirection = null;
          this.orderQuery.orderField = null;
          break;
      }
      if (this.backOrder) {
        this.query.pageNo = this.startPage;
        this.getTableData(this.query, 'search');
      }
    }
  }
};
