import { deepClone } from '@/utils/methods/common';
import {
  getTableColumnsCacheById,
  setTableColumnsCacheById,
  deleteTableColumnsCacheById
} from '../utils/methods';

export default {
  data() {
    return {
      _dealedColumns: [] // 处理操作列后的 columns
    };
  },
  watch: {
    minColumnWidth() {
      this.resize();
    },
    columns: {
      deep: true,
      handler: function () {
        this._dealedColumns = this.dealOperationWithColumns();
        this.renderNormalColumns();
      }
    }
  },
  mounted() {
    this._dealedColumns = this.dealOperationWithColumns();
    this.renderNormalColumns();
    this.mixin_listenerPolicy_addListener(
      this.$el,
      'resizeObserver',
      this.resize
    );
  },
  computed: {
    leafColumns() {
      let leaf = [];
      function loop(cols = []) {
        cols.forEach((c) => {
          if (c.merge.length === 0) {
            leaf.push(c);
          } else {
            loop(c.merge);
          }
        });
      }
      loop(this.columns_);
      return leaf;
    }
  },
  methods: {
    customColumns(columns) {
      this.id && setTableColumnsCacheById(this.id, columns);
      this.columnsCache = columns;
      this.renderNormalColumns();
    },
    dealOperationWithColumns() {
      let columns = [];
      let operateColumn = {
        label: '操作',
        prop: 'operate',
        fixed: 'right',
        width: this.toolWidth,
        widthGrow: false
      };
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].prop === 'operate') {
          operateColumn = { ...operateColumn, ...this.columns[i] };
        } else {
          columns.push(this.columns[i]);
        }
      }
      if (this.showTool) {
        columns.push(operateColumn);
      }
      return columns;
    },
    resize() {
      let tempColumns_ = this.loopColumns(deepClone(this._dealedColumns));
      if (this.id) {
        this.columnsCache = getTableColumnsCacheById(this.id);
        if (
          tempColumns_.length !== this.columnsCache.length ||
          tempColumns_.some(
            (k) =>
              !this.columnsCache.find(
                (y) => y.prop === k.prop && y.label === k.label
              )
          )
        ) {
          deleteTableColumnsCacheById(this.id);
          this.columnsCache = tempColumns_;
        }
      } else {
        this.columnsCache =
          this.columnsCache.length === 0 ? tempColumns_ : this.columnsCache;
      }
      this.columns_ = this.columnsCache
        .filter((col) => !col.filter)
        .map((col) => {
          let temp = tempColumns_.find((k) => k.prop === col.prop) || {};
          return {
            ...temp,
            ...col,
            component: temp.component
          };
        });
      this.calculateColumnsWidth();
    },
    headerWrapperCalculate() {
      this.headerWrapper &&
        this.mixin_listenerPolicy_removeListener(
          this.headerWrapper,
          'resizeObserver',
          this.progressHeightChange
        );
      this.$nextTick(() => {
        this.headerWrapper = this.$el.querySelector(
          '.el-table__header-wrapper'
        );
        this.progressHeightChange();
        this.mixin_listenerPolicy_addListener(
          this.headerWrapper,
          'resizeObserver',
          this.progressHeightChange
        );
      });
    },
    progressHeightChange() {
      let topHeight = this.headerWrapper.clientHeight;
      this.$refs.progress.style.top =
        ('border' in this.$attrs ? topHeight - 1 : topHeight - 2) + 'px';
    },
    renderNormalColumns() {
      this.resize();
      this.key++;
      this.headerWrapperCalculate();
    },
    // table计算列宽
    calculateColumnsWidth() {
      let tableWidthMinWidth = 0; // 预测table最小宽度
      let toolWidth = this.id ? this.toolWidth : 0; // 小齿轮图标宽度
      let typesColumnsWidth = this.typesColumns.reduce(
        (sum, item) => sum + item.col.width,
        0
      ); // 特殊列总宽度
      //开启border属性时，需要减去一个border宽度的距离
      let borderWidth = this.tableBind.border ? 1 : 0;
      let { scrollY, gutterWidth } = this.$refs.elTable.layout;
      let mixin_resize_containerWidth =
        this.$refs.elTable.$el.clientWidth -
        typesColumnsWidth -
        toolWidth -
        borderWidth -
        (scrollY ? gutterWidth : 0);
      for (let item of this.leafColumns) {
        if (item.width && !isNaN(Number(item.width))) {
          item.width = Number(item.width);
          tableWidthMinWidth += item.width;
        } else {
          item.width = this.minColumnWidth;
          tableWidthMinWidth += this.minColumnWidth;
        }
      }
      if (mixin_resize_containerWidth >= tableWidthMinWidth) {
        let filterColumns = this.leafColumns.filter(
          (col) => col.widthGrow !== false
        );
        let length = filterColumns.length;
        let averageWidth = Math.floor(
          (mixin_resize_containerWidth - tableWidthMinWidth) / length
        ); // 平均增加宽度
        let extraWidth =
          (mixin_resize_containerWidth - tableWidthMinWidth) % length; // 加上‘平均增加宽度’后剩下的宽度
        for (let item of filterColumns) {
          //给每个字段补充宽度
          if (extraWidth !== 0) {
            item.width += averageWidth + 1;
            extraWidth--;
          } else {
            item.width += averageWidth;
          }
        }
      }
    }
  }
};
